export const ActionView = {
  SET_LOADING_SCREEN: 'SET_LOADING_SCREEN',
  SHOW_MODAL_ALERT: 'SHOW_MODAL_ALERT'
}

export const ActionFetch = {
  AUTHENTICATION: 'AUTHENTICATION',
  BANNERS_LIST: 'BANNERS_LIST',
  BANNERS_DETAIL: 'BANNERS_DETAIL',
  BANNERS_ADD: 'BANNERS_ADD',
  BANNERS_UPDATE: 'BANNERS_UPDATE',
  BANNERS_DELETE: 'BANNERS_DELETE',
  BANNERS_SORT: 'BANNERS_SORT',
  PROJECTS: {
    CATEGORIES: {
      INDEX: 'INDEX',
      DELETE: 'DELETE',
      DETAIL: 'DETAIL',
      ADD: 'ADD',
      UPDATE: 'UPDATE'
    },
    PUBLICS: {
      INDEX: 'PROJECT_PUBLICS_INDEX',
      UPDATE: 'PROJECT_PUBLICS_UPDATE'
    },
    MAIN: {
      INDEX: 'PROJECT_MAIN_INDEX',
      DELETE: 'PROJECT_MAIN_DELETE',
      DETAIL: 'PROJECT_MAIN_DETAIL',
      ADD: 'PROJECT_MAIN_ADD',
      UPDATE: 'PROJECT_MAIN_UPDATE',
      SORT: 'PROJECT_SORT_UPDATE',
      CLEAR_PROJECT_LIST: 'CLEAR_PROJECT_LIST'
    },
    ADVANCESEARCH: {
      INDEX: 'PROJECT_ADVANCESEARCH_INDEX',
      DELETE: 'PROJECT_ADVANCESEARCH_DELETE',
      DETAIL: 'PROJECT_ADVANCESEARCH_DETAIL',
      ADD: 'PROJECT_ADVANCESEARCH_ADD',
      UPDATE: 'PROJECT_ADVANCESEARCH_UPDATE',
      SORT: 'PROJECT_ADVANCESEARCH_SORT_UPDATE'
    },
    BANNERSLIDE: {
      INDEX: 'BANNERSLIDE_INDEX',
      DELETE: 'BANNERSLIDE_DELETE',
      DETAIL: 'BANNERSLIDE_DETAIL',
      ADD: 'BANNERSLIDE_ADD',
      UPDATE: 'BANNERSLIDE_UPDATE',
      SORT: 'BANNERSLIDE_SORT_UPDATE'
    },
    CONTENT: {
      INDEX: 'CONTENT_INDEX',
      DELETE: 'CONTENT_DELETE',
      DETAIL: 'CONTENT_DETAIL',
      ADD: 'CONTENT_ADD',
      UPDATE: 'CONTENT_UPDATE',
      SORT: 'CONTENT_SORT_UPDATE'
    },
    INFO: {
      INDEX: 'PROJECT_INFO_INDEX',
      DELETE: 'PROJECT_INFO_DELETE',
      DETAIL: 'PROJECT_INFO_DETAIL',
      ADD: 'PROJECT_INFO_ADD',
      UPDATE: 'PROJECT_INFO_UPDATE'
    },
    GALLERY: {
      INDEX: 'PROJECT_GALLERY_INDEX',
      DELETE: 'PROJECT_GALLERY_DELETE',
      DETAIL: 'PROJECT_GALLERY_DETAIL',
      ADD: 'PROJECT_GALLERY_ADD',
      UPDATE: 'PROJECT_GALLERY_UPDATE',
      SORT: 'PROJECT_GALLERY_SORT_UPDATE'
    },
    VIDEO: {
      INDEX: 'PROJECT_VIDEO_INDEX',
      DELETE: 'PROJECT_VIDEO_DELETE',
      DETAIL: 'PROJECT_VIDEO_DETAIL',
      ADD: 'PROJECT_VIDEO_ADD',
      UPDATE: 'PROJECT_VIDEO_UPDATE',
      SORT: 'PROJECT_VIDEO_SORT_UPDATE'
    },
    PANORAMAS: {
      INDEX: 'PROJECT_PANORAMAS_INDEX',
      DELETE: 'PROJECT_PANORAMAS_DELETE',
      DETAIL: 'PROJECT_PANORAMAS_DETAIL',
      ADD: 'PROJECT_PANORAMAS_ADD',
      UPDATE: 'PROJECT_PANORAMAS_UPDATE',
      SORT: 'PROJECT_PANORAMAS_SORT_UPDATE'
    },
    LOCATION: {
      INDEX: 'PROJECT_LOCATION_INDEX',
      DELETE: 'PROJECT_LOCATION_DELETE',
      DETAIL: 'PROJECT_LOCATION_DETAIL',
      ADD: 'PROJECT_LOCATION_ADD',
      UPDATE: 'PROJECT_LOCATION_UPDATE'
    },
    BROCHURE: {
      INDEX: 'PROJECT_BROCHURE_INDEX',
      DELETE: 'PROJECT_BROCHURE_DELETE',
      DETAIL: 'PROJECT_BROCHURE_DETAIL',
      ADD: 'PROJECT_BROCHURE_ADD',
      UPDATE: 'PROJECT_BROCHURE_UPDATE'
    },
    FLOORS: {
      INDEX: 'PROJECT_FLOORS_INDEX',
      DELETE: 'PROJECT_FLOORS_DELETE',
      DETAIL: 'PROJECT_FLOORS_DETAIL',
      ADD: 'PROJECT_FLOORS_ADD',
      UPDATE: 'PROJECT_FLOORS_UPDATE',
      SORT: 'PROJECT_FLOORS_SORT_UPDATE'
    },
    UNITS: {
      INDEX: 'PROJECT_UNITS_INDEX',
      DELETE: 'PROJECT_UNITS_DELETE',
      DETAIL: 'PROJECT_UNITS_DETAIL',
      ADD: 'PROJECT_UNITS_ADD',
      UPDATE: 'PROJECT_UNITS_UPDATE',
      SORT: 'PROJECT_UNITS_SORT_UPDATE'
    },
    PROGRESS: {
      INDEX: 'PROJECT_PROGRESS_INDEX',
      DELETE: 'PROJECT_PROGRESS_DELETE',
      DETAIL: 'PROJECT_PROGRESS_DETAIL',
      ADD: 'PROJECT_PROGRESS_ADD',
      UPDATE: 'PROJECT_PROGRESS_UPDATE'
    },
    REGISTERSETTING: {
      PROJECTLIST: 'PROJECT_REGISTERSETTING_PROJECTLIST',
      LIST: 'PROJECT_REGISTERSETTING_LIST',
      CREATE: 'PROJECT_REGISTERSETTING_CREATE',
      UPDATE: 'PROJECT_REGISTERSETTING_UPDATE'
    },
    REGISTER: {
      LIST: 'PROJECT_REGISTER_LIST',
      SEND_CRM: 'PROJECT_REGISTER_SEND_CRM',
      SEND_CRM_ALL: 'PROJECT_REGISTER_SEND_CRM_ALL'
    }
  },
  TRANSPORTLINK: {
    LIST: 'TRANSPORTLINK_LIST',
    CREATE: 'TRANSPORTLINK_CREATE',
    UPDATE: 'TRANSPORTLINK_UPDATE',
    UPDATESORT: 'TRANSPORTLINK_UPDATESORT',
    DELETE: 'TRANSPORTLINK_DELETE'
  },
  SEARCH_LOCATION: {
    LIST: 'SEARCH_LOCATION_LIST',
    CREATE: 'SEARCH_LOCATION_CREATE',
    UPDATE: 'SEARCH_LOCATION_UPDATE',
    UPDATESORT: 'SEARCH_LOCATION_UPDATESORT',
    DELETE: 'SEARCH_LOCATION_DELETE'
  },
  SEARCH_PRICE: {
    LIST: 'SEARCH_PRICE_LIST',
    CREATE: 'SEARCH_PRICE_CREATE',
    UPDATE: 'SEARCH_PRICE_UPDATE',
    UPDATESORT: 'SEARCH_PRICE_UPDATESORT',
    DELETE: 'SEARCH_PRICE_DELETE'
  },
  SEO_GOOGLE: {
    GET_CONFIG: 'SEO_GOOGLE_GET_CONFIG',
    GET_TAG: 'SEO_GOOGLE_GET_TAG',
    GET_THANKYOU: 'SEO_GOOGLE_GET_THANKYOU',
    UPDATE_CONFIG: 'SEO_GOOGLE_UPDATE_CONFIG',
    SAVE_TAG: 'SEO_GOOGLE_SAVE_TAG',
    SAVE_THANKYOU: 'SEO_GOOGLE_SAVE_THANKYOU'
  },
  SEO_FACEBOOK: {
    GET_CONFIG: 'SEO_FACEBOOK_GET_CONFIG',
    GET_TAG: 'SEO_FACEBOOK_GET_TAG',
    GET_THANKYOU: 'SEO_FACEBOOK_GET_THANKYOU',
    SAVE_CONFIG: 'SEO_FACEBOOK_SAVE_CONFIG',
    SAVE_TAG: 'SEO_FACEBOOK_SAVE_TAG',
    SAVE_THANKYOU: 'SEO_FACEBOOK_SAVE_THANKYOU',
    UPLOAD: 'SEO_FACEBOOK_UPLOAD'
  },
  ATTRIBUTES: {
    INDEX: 'ATTRIBUTES_INDEX',
    DELETE: 'ATTRIBUTES_DELETE',
    DETAIL: 'ATTRIBUTES_DETAIL',
    ADD: 'ATTRIBUTES_ADD',
    UPDATE: 'ATTRIBUTES_UPDATE',
    SORT: 'ATTRIBUTES_SORT'
  },
  SYSTEM_CONFIGS: {
    LIST: 'SYSTEM_CONFIGS_LIST',
    UPDATE: 'SYSTEM_CONFIGS_UPDATE'
  },
  EXTERNAL: {
    PROJECT_GULEAD: {
      LIST: 'EXTERNAL_PROJECT_GULEAD_LIST'
    }
  },
  ICON: {
    LIST: 'ICON_LIST'
  },
  PROJECT_NEWS: {
    LIST: 'PROJECT_NEWS_LIST',
    DETAIL: 'PROJECT_NEWS_DETAIL',
    CREATE: 'PROJECT_NEWS_CREATE',
    UPDATE: 'PROJECT_NEWS_UPDATE',
    UPDATESORT: 'PROJECT_NEWS_UPDATESORT',
    DELETE: 'PROJECT_NEWS_DELETE'
  },
  PROJECT_ARTICLE: {
    LIST: 'PROJECT_ARTICLE_LIST',
    DETAIL: 'PROJECT_ARTICLE_DETAIL',
    CREATE: 'PROJECT_ARTICLE_CREATE',
    UPDATE: 'PROJECT_ARTICLE_UPDATE',
    UPDATESORT: 'PROJECT_ARTICLE_UPDATESORT',
    DELETE: 'PROJECT_ARTICLE_DELETE'
  },
  CAMPAIGNS: {
    LIST: 'CAMPAIGNS_LIST',
    DETAIL: 'CAMPAIGNS_DETAIL',
    CREATE: 'CAMPAIGNS_CREATE',
    UPDATE: 'CAMPAIGNS_UPDATE',
    UPDATE_PUBLISH: 'CAMPAIGNS_UPDATE_PUBLISH',
    UPDATESORT: 'CAMPAIGNS_UPDATESORT',
    DELETE: 'CAMPAIGNS_DELETE'
  },
  CAMPAIGNBANNER: {
    LIST: 'CAMPAIGNBANNER_LIST',
    DETAIL: 'CAMPAIGNBANNER_DETAIL',
    CREATE: 'CAMPAIGNBANNER_CREATE',
    UPDATE: 'CAMPAIGNBANNER_UPDATE',
    UPDATE_PUBLISH: 'CAMPAIGNBANNER_UPDATE_PUBLISH',
    UPDATESORT: 'CAMPAIGNBANNER_UPDATESORT',
    DELETE: 'CAMPAIGNBANNER_DELETE'
  },
  CAMPAIGNPROJECT: {
    LIST: 'CAMPAIGNPROJECT_LIST',
    CREATE: 'CAMPAIGNPROJECT_CREATE',
    UPDATE: 'CAMPAIGNPROJECT_UPDATE',
    SORT: 'CAMPAIGNPROJECT_SORT',
    DELETE: 'CAMPAIGNPROJECT_DELETE'
  },
  CAMPAIGNREGISTERPROJECT: {
    LIST: 'CAMPAIGNREGISTERPROJECT_LIST',
    CREATE: 'CAMPAIGNREGISTERPROJECT_CREATE',
    UPDATE: 'CAMPAIGNREGISTERPROJECT_UPDATE',
    DELETE: 'CAMPAIGNREGISTERPROJECT_DELETE',
    SORT: 'CAMPAIGNREGISTERPROJECT_SORT'
  },
  CAMPAIGNCONCEPT: {
    LIST: 'CAMPAIGNCONCEPT_LIST',
    CREATE: 'CAMPAIGNCONCEPT_CREATE',
    UPDATE: 'CAMPAIGNCONCEPT_UPDATE',
    DELETE: 'CAMPAIGNCONCEPT_DELETE'
  },
  CAMPAIGNREGISTERSETTING: {
    LIST: 'CAMPAIGNREGISTERSETTING_LIST',
    UPDATE: 'CAMPAIGNREGISTERSETTING_UPDATE'
  },
  CAMPAIGNSEO: {
    CONTENT: {
      LIST: 'CAMPAIGNSEO_CONTENT_LIST',
      CREATE: 'CAMPAIGNSEO_CONTENT_CREATE',
      UPDATE: 'CAMPAIGNSEO_CONTENT_UPDATE',
      FBLIST: 'CAMPAIGNSEO_CONTENT_FBLIST',
      FBCREATE: 'CAMPAIGNSEO_CONTENT_FBCREATE',
      FBUPDATE: 'CAMPAIGNSEO_CONTENT_FBUPDATE'
    },
    THANKYOU: {
      LIST: 'CAMPAIGNSEO_THANKYOU_LIST',
      CREATE: 'CAMPAIGNSEO_THANKYOU_CREATE',
      UPDATE: 'CAMPAIGNSEO_THANKYOU_UPDATE',
      FBLIST: 'CAMPAIGNSEO_THANKYOU_FBLIST',
      FBCREATE: 'CAMPAIGNSEO_THANKYOU_FBCREATE',
      FBUPDATE: 'CAMPAIGNSEO_THANKYOU_FBUPDATE'
    }
  },
  CAMPAIGNTITLETHUMBNAIL: {
    INFO: 'CAMPAIGNTITLETHUMBNAIL_INFO',
    UPDATE: 'CAMPAIGNTITLETHUMBNAIL_UPDATE'
  },
  CONFIG: {
    EMAIL: {
      LIST: 'CONFIG_EMAIL_LIST',
      CREATE: 'CONFIG_EMAIL_CREATE',
      UPDATE: 'CONFIG_EMAIL_UPDATE',
      DELETE: 'CONFIG_EMAIL_DELETE'
    },
    SUBJECT: {
      LIST: 'CONFIG_SUBJECT_LIST',
      CREATE: 'CONFIG_SUBJECT_CREATE',
      UPDATE: 'CONFIG_SUBJECT_UPDATE',
      DELETE: 'CONFIG_SUBJECT_DELETE'
    },
    MESSAGE: {
      LIST: 'CONFIG_MESSAGE_LIST',
      DETAIL: 'CONFIG_MESSAGE_DETAIL'
    },
    MAP: {
      LIST: 'CONFIG_MAP_LIST',
      UPDATE: 'CONFIG_MAP_UPDATE'
    }
  },
  ABOUT: {
    BANNER: {
      DETAIL: 'ABOUT_BANNER_DETAIL',
      UPDATE: 'ABOUT_BANNER_UPDATE'
    },
    BRANDING: {
      DETAIL: 'ABOUT_BRANDING_DETAIL',
      UPDATE: 'ABOUT_BRANDING_UPDATE'
    },
    MILESTONE: {
      LIST: 'ABOUT_MILESTONE_LIST',
      DETAIL: 'ABOUT_MILESTONE_DETAIL',
      CREATE: 'ABOUT_MILESTONE_CREATE',
      UPDATE: 'ABOUT_MILESTONE_UPDATE',
      UPDATESORT: 'ABOUT_MILESTONE_UPDATESORT',
      DELETE: 'ABOUT_MILESTONE_DELETE'
    }
  },
  GUFAMILY: {
    CATEGORY: {
      LIST: 'GUFAMILY_CATEGORY_LIST',
      DETAIL: 'GUFAMILY_CATEGORY_DETAIL',
      CREATE: 'GUFAMILY_CATEGORY_CREATE',
      UPDATE: 'GUFAMILY_CATEGORY_UPDATE',
      UPDATESORT: 'GUFAMILY_CATEGORY_UPDATESORT',
      DELETE: 'GUFAMILY_CATEGORY_DELETE'
    },
    PRIVILEGE: {
      LIST: 'GUFAMILY_PRIVILEGE_LIST',
      DETAIL: 'GUFAMILY_PRIVILEGE_DETAIL',
      CREATE: 'GUFAMILY_PRIVILEGE_CREATE',
      UPDATE: 'GUFAMILY_PRIVILEGE_UPDATE',
      UPDATESORT: 'GUFAMILY_PRIVILEGE_UPDATESORT',
      DELETE: 'GUFAMILY_PRIVILEGE_DELETE'
    },
    ACTIVITY: {
      LIST: 'GUFAMILY_ACTIVITY_LIST',
      DETAIL: 'GUFAMILY_ACTIVITY_DETAIL',
      CREATE: 'GUFAMILY_ACTIVITY_CREATE',
      UPDATE: 'GUFAMILY_ACTIVITY_UPDATE',
      UPDATESORT: 'GUFAMILY_ACTIVITY_UPDATESORT',
      DELETE: 'GUFAMILY_ACTIVITY_DELETE'
    },
    BANNER: {
      LIST: 'GUFAMILY_BANNER_LIST',
      DETAIL: 'GUFAMILY_BANNER_DETAIL',
      CREATE: 'GUFAMILY_BANNER_CREATE',
      UPDATE: 'GUFAMILY_BANNER_UPDATE',
      UPDATESORT: 'GUFAMILY_BANNER_UPDATESORT',
      DELETE: 'GUFAMILY_BANNER_DELETE'
    }
  },
  PRIVACY: {
    DETAIL: 'PRIVACY_DETAIL',
    UPDATE: 'PRIVACY_UPDATE'
  },
  TERM: {
    DETAIL: 'TERM_DETAIL',
    UPDATE: 'TERM_UPDATE'
  },
  MOBILEAPP: {
    DETAIL: 'MOBILEAPP_DETAIL',
    UPDATE: 'MOBILEAPP_UPDATE'
  },
  BTSCATEGORY: {
    LIST: 'BTSCATEGORY_LIST',
    DETAIL: 'BTSCATEGORY_DETAIL',
    CREATE: 'BTSCATEGORY_CREATE',
    UPDATE: 'BTSCATEGORY_UPDATE',
    UPDATESORT: 'BTSCATEGORY_UPDATESORT',
    DELETE: 'BTSCATEGORY_DELETE'
  },
  FILEMANAGER: {
    LIST: 'FILEMANAGER_LIST',
    CREATE: 'FILEMANAGER_CREATE',
    DELETE: 'FILEMANAGER_DELETE'
  },
  BANNERCONTACT: {
    DETAIL: 'BANNERCONTACT_DETAIL',
    UPDATE: 'BANNERCONTACT_UPDATE'
  },
  HOME: {
    DETAIL: 'HOME_DETAIL',
    UPDATE: 'HOME_UPDATE'
  },
  PROMOTION: {
    LIST: 'PROMOTION_LIST',
    DETAIL: 'PROMOTION_DETAIL',
    CREATE: 'PROMOTION_CREATE',
    UPDATE: 'PROMOTION_UPDATE',
    UPDATESORT: 'PROMOTION_UPDATESORT',
    DELETE: 'PROMOTION_DELETE'
  },
  FOREIGNBUYER: {
    DETAIL: 'FOREIGNBUYER_DETAIL',
    UPDATE: 'FOREIGNBUYER_UPDATE'
  },
  SENSE: {
    LIST: 'SENSE_LIST'
  },
  JOB: {
    DETAIL: 'JOB_DETAIL',
    UPDATE: 'JOB_UPDATE'
  },
  ROLE: {
    LIST: 'ROLE_LIST',
    DETAIL: 'ROLE_DETAIL',
    CREATE: 'ROLE_CREATE',
    UPDATE: 'ROLE_UPDATE',
    DELETE: 'ROLE_DELETE'
  },
  USER: {
    LIST: 'USER_LIST',
    DETAIL: 'USER_DETAIL',
    CREATE: 'USER_CREATE',
    UPDATE: 'USER_UPDATE',
    DELETE: 'USER_DELETE'
  },
  SITEMAP: {
    DETAIL: 'SITEMAP_DETAIL',
    UPDATE: 'SITEMAP_UPDATE'
  },
  CALCULATOR: {
    DETAIL: 'CALCULATOR_DETAIL',
    UPDATE: 'CALCULATOR_UPDATE'
  }
}
