import Vue from 'vue'
import { SET_CONFIGS } from './mutations'

export default {
  async getConfigs ({ commit }) {
    const { data } = await Vue.axios.get('configs', {
      params: {
        pagination: false
      },
      baseURL: process.env.VUE_APP_API_ENDPOINT_V3
    })

    commit(SET_CONFIGS, data)

    return data
  },
  async updateConfigs ({ commit }, configs) {
    const { data } = await Vue.axios.put(
      'configs',
      configs,
      {
        baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
        }
      }
    )

    commit(SET_CONFIGS, data)

    return data
  }
}
