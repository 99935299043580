import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.PROJECT_ARTICLE.LIST]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.projectArticles.list($params)).then($res => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.PROJECT_ARTICLE.LIST, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.PROJECT_ARTICLE.DETAIL]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.projectArticles.getInfo($params.id)).then($res => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.PROJECT_ARTICLE.DETAIL, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.PROJECT_ARTICLE.CREATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser || JSON.parse(localStorage.getItem('cmsUser'))

      $params.createdBy = user.id
      $params.updatedBy = user.id

      return AxiosRequest(API.projectArticles.create(), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.PROJECT_ARTICLE.CREATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.PROJECT_ARTICLE.UPDATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser || JSON.parse(localStorage.getItem('cmsUser'))

      $params.createdBy = user.id
      $params.updatedBy = user.id

      return AxiosRequest(API.projectArticles.update($params.id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.PROJECT_ARTICLE.UPDATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.PROJECT_ARTICLE.UPDATESORT]: ({ commit, rootState }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser || JSON.parse(localStorage.getItem('cmsUser'))

      $params.updatedBy = user.id

      return AxiosRequest(API.projectArticles.updateSort(), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.PROJECT_ARTICLE.UPDATESORT, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)

        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.PROJECT_ARTICLE.DELETE]: ({ commit, rootState }, $id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser

      return AxiosRequest(API.projectArticles.delete($id), {}, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.PROJECT_ARTICLE.DELETE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    }
  }
}

export default request
