import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {

    [ActionFetch.ATTRIBUTES.INDEX]: ({ commit }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.projectAttributes.index(params.moduleName))
        .then(res => {
          const data = res.data
          if (data.success && data.data && params.toStoreData) {
            commit(MutationStore.ATTRIBUTES.INDEX, data.data)
          }
          commit(MutationView.SET_LOADING_SCREEN, false)
          return data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.ATTRIBUTES.ADD]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projectAttributes.add, { ...params, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.ATTRIBUTES.UPDATE]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projectAttributes.update(params.id), { ...params.data, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.ATTRIBUTES.DELETE]: ({ commit, rootState }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.projectAttributes.delete(id), {}, rootState.cmsUser.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.ATTRIBUTES.SORT]: ({ commit, rootState }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projectAttributes.updateSort(), { ...$params, updatedBy: user.id }, user.token)
        .then(($res) => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return $res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    }

  }
}

export default request
