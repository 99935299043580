import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.PROJECTS.GALLERY.INDEX]: ({ commit }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.projects.gallerys.index(id))
        .then(res => {
          const data = res.data
          if (data.success && data.data) {
            commit(MutationStore.PROJECTS.GALLERY.INDEX, data.data)
          }
          commit(MutationView.SET_LOADING_SCREEN, false)
          return data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.PROJECTS.GALLERY.ADD]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.gallerys.add, { ...params, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.PROJECTS.GALLERY.UPDATE]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.gallerys.update(params.id), { ...params.data, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.PROJECTS.GALLERY.DELETE]: ({ commit, rootState }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.projects.gallerys.delete(id), {}, rootState.cmsUser.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          const data = res.data
          if (data.success) {
            const gallerys = rootState.project.gallerys
            const resp = (gallerys && gallerys.filter(each => {
              return each.id !== id
            })) || []
            commit(MutationStore.PROJECTS.GALLERY.INDEX, resp)
          }

          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },
    [ActionFetch.PROJECTS.GALLERY.SORT]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.gallerys.sort, { ...params, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    }

  }
}

export default request
