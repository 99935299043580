import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.CAMPAIGNSEO.CONTENT.LIST]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.campaignSeo.google.content.list($params)).then($res => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.CONTENT.LIST, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.CONTENT.CREATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.createdBy = user.id
      $params.updatedBy = user.id

      return AxiosRequest(API.campaignSeo.google.content.create($params.ref_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.CONTENT.CREATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message.toString())
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.CONTENT.UPDATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.updatedBy = user.id

      return AxiosRequest(API.campaignSeo.google.content.update($params.ref_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.CONTENT.UPDATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.THANKYOU.LIST]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.campaignSeo.google.thankyou.list($params)).then($res => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.THANKYOU.LIST, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.THANKYOU.CREATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.createdBy = user.id
      $params.updatedBy = user.id

      return AxiosRequest(API.campaignSeo.google.thankyou.create($params.ref_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.THANKYOU.CREATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.THANKYOU.UPDATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.updatedBy = user.id

      return AxiosRequest(API.campaignSeo.google.thankyou.update($params.ref_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.THANKYOU.UPDATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.CONTENT.FBLIST]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.campaignSeo.fb.content.list($params)).then($res => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.CONTENT.FBLIST, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.CONTENT.FBCREATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.createdBy = user.id
      $params.updatedBy = user.id

      return AxiosRequest(API.campaignSeo.fb.content.create($params.ref_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.CONTENT.FBCREATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.CONTENT.FBUPDATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.updatedBy = user.id

      return AxiosRequest(API.campaignSeo.fb.content.update($params.ref_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.CONTENT.FBUPDATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.THANKYOU.FBLIST]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.campaignSeo.fb.thankyou.list($params)).then($res => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.THANKYOU.FBLIST, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.THANKYOU.FBCREATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.createdBy = user.id
      $params.updatedBy = user.id

      return AxiosRequest(API.campaignSeo.fb.thankyou.create($params.ref_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.THANKYOU.FBCREATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNSEO.THANKYOU.FBUPDATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.updatedBy = user.id

      return AxiosRequest(API.campaignSeo.fb.thankyou.update($params.ref_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNSEO.THANKYOU.FBUPDATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    }
  }
}

export default request
