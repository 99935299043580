import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'
import router from '@/router'

const request = {
  state: {
  },
  actions: {

    [ActionFetch.AUTHENTICATION]: ({ dispatch, commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.authentication, params)
        .then(res => {
          const data = res.data
          if (data.success) {
            commit(MutationStore.SET_USER_AUTHEN, data.data)
            router.push({
              path: `/`
            })
          } else {
            commit(MutationView.SHOW_MODAL_ALERT, data.message)
          }
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.response.data.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    }

  }
}

export default request
