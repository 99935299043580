import { ActionView } from '@/store/actionTypes'
import { MutationView } from '@/store/mutationTypes'

const request = {
  state: {
    isLoadingScreen: false,
    alertMessage: {
      message: null,
      show: false
    }
  },
  actions: {
    [ActionView.SET_LOADING_SCREEN]: ({ commit }, isLoading) => {
      commit(MutationView.SET_LOADING_SCREEN, isLoading)
    }
  },
  mutations: {
    [MutationView.SET_LOADING_SCREEN]: (state, isLoading) => {
      state.isLoadingScreen = isLoading
    },
    [MutationView.SHOW_MODAL_ALERT]: (state, message) => {
      state.alertMessage = {
        message,
        show: true
      }
    },
    [MutationView.HIDE_MODAL_ALERT]: (state, message) => {
      state.alertMessage = {
        message,
        show: false
      }
    }
  }
}

export default request
