import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.PROJECTS.REGISTER.LIST]: ({ commit }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      let {
        project_id,
        orderBy: order_by,
        direction,
        start,
        end,
        limit,
        page,
        campaign_id
      } = $params

      direction = direction.toLowerCase()

      const endpoint = project_id ? `/projects/${project_id}/registers` : '/project-registers'

      return AxiosRequest(
        `GET ${endpoint}`,
        { order_by, direction, start, end, limit, page, campaign_id },
        null,
        { apiVersion: '/v2' }
      ).then($res => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.PROJECTS.REGISTER.LIST, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.PROJECTS.REGISTER.SEND_CRM]: ({ commit, rootState }, $id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser

      return AxiosRequest(API.projects.register.sendCrm($id), {}, user.token).then(($res) => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.PROJECTS.REGISTER.SEND_CRM, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.PROJECTS.REGISTER.SEND_CRM_ALL]: ({ commit, rootState }) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser

      return AxiosRequest(API.projects.register.sendCrmAll(), {}, user.token).then(($res) => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.PROJECTS.REGISTER.SEND_CRM_ALL, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    }

  }
}

export default request
