<template>
  <div v-if="!$route.meta.public">
    <div id="app">
      <side-bar :active="sideBarActive" :toggleSideBar="toggleSideBar" />
      <div id="content" :class="sideBarActive ? '' : 'active'">
        <app-header
          :sideBarActive="sideBarActive"
          :toggleSideBar="toggleSideBar"
        ></app-header>
        <router-view />
      </div>
      <div v-show="view.isLoadingScreen" id="loading_screen">
        <img src="@/assets/loading.gif" alt="loading please wait ..." />
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import './app.scss'
import { mapState } from 'vuex'
import SideBar from './components/SideBar'
import AppHeader from './components/AppHeader'

export default {
  components: {
    SideBar,
    AppHeader
  },
  computed: {
    ...mapState(['view'])
  },
  data: () => ({
    sideBarActive: true
  }),
  methods: {
    toggleSideBar () {
      this.sideBarActive = !this.sideBarActive
    }
  },
  mounted () {
    document.addEventListener('focusin', (e) => {
      if (e.target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
        e.stopImmediatePropagation()
      }
    })
  },
  created () {
    const { innerWidth } = window
    this.sideBarActive = innerWidth > 768

    window.addEventListener('resize', () => {
      const { innerWidth } = window
      this.sideBarActive = innerWidth > 768
    })
  },
  watch: {
    'view.alertMessage' (newVal) {
      if (newVal.show && newVal.message) {
        this.$bvModal.msgBoxOk(newVal.message, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
          okOnly: true
        })
      }
    }
  }
}
</script>

<style lang="scss">
#main-app {
  margin-left: 250px;
}

@media (max-width: 768px) {
  #main-app {
    margin-left: 0px;
  }
}

#content {
  width: calc(100% - 250px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(216, 216, 216, 0.5);
}

#content.active {
  width: 100%;
}
</style>
