import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.SEARCH_PRICE.LIST]: ({ commit }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.searchPrice.list($params)).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEARCH_PRICE.LIST, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.SEARCH_PRICE.CREATE]: ({ commit, rootState }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser

      $params.createdBy = user.id
      $params.updatedBy = user.id

      return AxiosRequest(API.searchPrice.create(), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEARCH_PRICE.CREATE, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)

        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.SEARCH_PRICE.UPDATE]: ({ commit, rootState }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser

      $params.updatedBy = user.id

      return AxiosRequest(API.searchPrice.update($params.id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEARCH_PRICE.UPDATE, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)

        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.SEARCH_PRICE.UPDATESORT]: ({ commit, rootState }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser

      $params.updatedBy = user.id

      return AxiosRequest(API.searchPrice.updateSort(), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEARCH_PRICE.UPDATESORT, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)

        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.SEARCH_PRICE.DELETE]: ({ commit, rootState }, $id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser

      return AxiosRequest(API.searchPrice.delete($id), {}, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEARCH_PRICE.DELETE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    }
  }
}

export default request
