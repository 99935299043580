import axios from 'axios'

const apiEndpoint = process.env.VUE_APP_API_ENDPOINT
const apiVersion = process.env.VUE_APP_API_VERSION

/*
- api : string, exp: "POST user/password"
- params: object
- options: object {
  endpoint: string,
  apiVersion: string,
}
*/
export const AxiosRequest = (api, params, token, options) => {
  const [apiMethod, apiURL] = api.split(' ')

  let endpoint = apiEndpoint
  let version = `/${apiVersion}`

  if (options) {
    if (options && options.endpoint) {
      endpoint = options.endpoint
    }

    if (options && options.apiVersion !== null) {
      version = options.apiVersion
    }
  }

  const url = `${endpoint}${version}${apiURL}`
  const headers = {}

  if (token) {
    headers.Authorization = `Bearer ${token}`
  } else {
    const user = JSON.parse(localStorage.getItem('cmsUser'))
    if (user && user.token) {
      headers.Authorization = `Bearer ${user.token}`
    }
  }

  const config = {
    method: apiMethod,
    url,
    headers,
    crossDomain: true
  }

  if (params) {
    if (apiMethod.toLowerCase() === 'post' ||
      apiMethod.toLowerCase() === 'put' ||
      apiMethod.toLowerCase() === 'patch' ||
      apiMethod.toLowerCase() === 'delete') {
      config.data = params
    } else {
      if (typeof (params) === 'string') {
        config.url = `${config.url}/${params}`
      } else {
        config.params = params
      }
    }
  }

  return axios(config)
}
