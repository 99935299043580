import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.ICON.LIST]: ({ commit }) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.icon.list()).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.ICON.LIST, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)

        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    }
  }
}

export default request
