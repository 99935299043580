export const API = {
  authentication: 'POST /users',
  banners: {
    indexList: (page) => `GET /banners?module=${page}&orderBy=orderNum&ascdesc=ASC&limit=9999999&page=1`,
    add: 'POST /banners',
    detail: (id) => `GET /banners/${id}`,
    update: (id) => `PATCH /banners/${id}`,
    delete: (id) => `DELETE /banners/${id}`,
    sort: `POST /banners/sort`
  },
  projects: {
    publics: {
      index: (pid) => `GET /projectPublics?pid=${pid}`,
      update: (id) => `PATCH /projectPublics/${id}`
    },
    main: {
      index: ($cid = null) => `GET /projects?orderBy=orderNum&cid=${$cid}`,
      add: 'POST /projects',
      detail: (id) => `GET /projects/${id}`,
      update: (id) => `PATCH /projects/${id}`,
      delete: (id) => `DELETE /projects/${id}`,
      sort: `POST /projects/sort`
    },
    advanceSearch: {
      index: (pid) => `GET /projectAdvanceSearch?pid=${pid}`,
      add: 'POST /projectAdvanceSearch',
      detail: (id) => `GET /projectAdvanceSearch/${id}`,
      update: (id) => `PATCH /projectAdvanceSearch/${id}`,
      delete: (id) => `DELETE /projectAdvanceSearch/${id}`,
      sort: `POST /projectAdvanceSearch/sort`
    },
    categories: {
      index: 'GET /projectCategorys',
      add: 'POST /projectCategorys',
      detail: (id) => `GET /projectCategorys/${id}`,
      update: (id) => `PATCH /projectCategorys/${id}`,
      delete: (id) => `DELETE /projectCategorys/${id}`
    },
    bannerSlide: {
      index: (pid) => `GET /projectBanners?pid=${pid}&orderBy=orderNum&limit=9999999&page=1`,
      add: 'POST /projectBanners',
      detail: (id) => `GET /projectBanners/${id}`,
      update: (id) => `PATCH /projectBanners/${id}`,
      delete: (id) => `DELETE /projectBanners/${id}`,
      sort: `POST /projectBanners/sort`
    },
    content: {
      index: (pid) => `GET /projectContents?pid=${pid}&orderBy=orderNum`,
      add: 'POST /projectContents',
      detail: (id) => `GET /projectContents/${id}`,
      update: (id) => `PATCH /projectContents/${id}`,
      delete: (id) => `DELETE /projectContents/${id}`,
      sort: `POST /projectContents/sort`
    },
    info: {
      index: (pid) => `GET /projectInfos?pid=${pid}`,
      add: 'POST /projectInfos',
      detail: (id) => `GET /projectInfos/${id}`,
      update: (id) => `PATCH /projectInfos/${id}`,
      delete: (id) => `DELETE /projectInfos/${id}`
    },
    gallerys: {
      index: (pid) => `GET /projectGallerys?pid=${pid}&orderBy=orderNum`,
      add: 'POST /projectGallerys',
      detail: (id) => `GET /projectGallerys/${id}`,
      update: (id) => `PATCH /projectGallerys/${id}`,
      delete: (id) => `DELETE /projectGallerys/${id}`,
      sort: `POST /projectGallerys/sort`
    },
    video: {
      index: (pid) => `GET /projectVideos?pid=${pid}&orderBy=orderNum`,
      add: 'POST /projectVideos',
      detail: (id) => `GET /projectVideos/${id}`,
      update: (id) => `PATCH /projectVideos/${id}`,
      delete: (id) => `DELETE /projectVideos/${id}`,
      sort: `POST /projectVideos/sort`
    },
    panoramas: {
      index: (pid) => `GET /projectPanoramas?pid=${pid}&orderBy=orderNum`,
      add: 'POST /projectPanoramas',
      detail: (id) => `GET /projectPanoramas/${id}`,
      update: (id) => `PATCH /projectPanoramas/${id}`,
      delete: (id) => `DELETE /projectPanoramas/${id}`,
      sort: `POST /projectPanoramas/sort`
    },
    location: {
      index: (pid) => `GET /projectLocations?pid=${pid}`,
      add: 'POST /projectLocations',
      detail: (id) => `GET /projectLocations/${id}`,
      update: (id) => `PATCH /projectLocations/${id}`,
      delete: (id) => `DELETE /projectLocations/${id}`
    },
    brochure: {
      index: (pid) => `GET /projectBrochures?pid=${pid}`,
      add: 'POST /projectBrochures',
      detail: (id) => `GET /projectBrochures/${id}`,
      update: (id) => `PATCH /projectBrochures/${id}`,
      delete: (id) => `DELETE /projectBrochures/${id}`
    },
    floors: {
      index: (pid) => `GET /projectFloorplans?pid=${pid}&orderBy=orderNum`,
      add: 'POST /projectFloorplans',
      detail: (id) => `GET /projectFloorplans/${id}`,
      update: (id) => `PATCH /projectFloorplans/${id}`,
      delete: (id) => `DELETE /projectFloorplans/${id}`,
      sort: `POST /projectFloorplans/sort`
    },
    units: {
      index: (pid) => `GET /projectUnitplans?pid=${pid}&orderBy=orderNum`,
      add: 'POST /projectUnitplans',
      detail: (id) => `GET /projectUnitplans/${id}`,
      update: (id) => `PATCH /projectUnitplans/${id}`,
      delete: (id) => `DELETE /projectUnitplans/${id}`,
      sort: `POST /projectUnitplans/sort`
    },
    progress: {
      index: (pid) => `GET /projectProgresses?pid=${pid}&orderBy=orderNum`,
      add: 'POST /projectProgresses',
      detail: (id) => `GET /projectProgresses/${id}`,
      update: (id) => `PATCH /projectProgresses/${id}`,
      delete: (id) => `DELETE /projectProgresses/${id}`
    },
    register: {
      list: (pid) => {
        return `GET /projectRegisterSettings?pid=${pid}&orderBy=id&ascdesc=undefined`
      },
      create: (pid) => {
        return `POST /projectRegisterSettings/create/${pid}`
      },
      update: (id) => {
        return `PATCH /projectRegisterSettings/${id}`
      },
      registerList: ($params) => {
        let api = `GET /projectRegisters?pid=${$params.pid}`

        api += `&orderBy=${$params.orderBy}`
        api += `&ascdesc=${$params.direction}`
        api += `&start=${$params.start}`
        api += `&end=${$params.end}`
        api += `&limit=${$params.limit}`
        api += `&page=${$params.page}`

        return api
      },
      sendCrm: (id) => `POST /projectRegisters/sendCRM/${id}`,
      sendCrmAll: () => `POST /projectRegisters/sendCRMAll`
    }
  },
  projectAttributes: {
    index: (moduleName) => `GET /projectAttributes?module=${moduleName}`,
    add: 'POST /projectAttributes',
    detail: (id) => `GET /projectAttributes/${id}`,
    update: (id) => `PATCH /projectAttributes/${id}`,
    delete: (id) => `DELETE /projectAttributes/${id}`,
    updateSort: () => `POST /projectAttributes/sort`
  },
  systemConfigs: {
    list: (key) => `GET /systemConfigs?configKey=${key}`,
    update: `POST /systemConfigs`
  },
  transportLink: {
    list: ($data) => `GET /searchTrains?type=${$data.type}&orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    create: ($data) => 'POST /searchTrains',
    update: (id) => `PATCH /searchTrains/${id}`,
    updateSort: () => `POST /searchTrains/sort`,
    delete: (id) => `DELETE /searchTrains/${id}`
  },
  external: {
    projectGuLead: {
      list: 'POST /remapi/mobile/Project'
    }
  },
  searchLocation: {
    list: ($data) => `GET /searchLocations?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    create: ($data) => `POST /searchLocations`,
    update: (id) => `PATCH /searchLocations/${id}`,
    updateSort: () => `POST /searchLocations/sort`,
    delete: (id) => `DELETE /searchLocations/${id}`
  },
  searchPrice: {
    list: ($data) => `GET /searchPrices?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    create: ($data) => `POST /searchPrices`,
    update: (id) => `PATCH /searchPrices/${id}`,
    updateSort: () => `POST /searchPrices/sort`,
    delete: (id) => `DELETE /searchPrices/${id}`
  },
  seoGoogle: {
    getConfig: () => `GET /seoGoogleConfig`,
    getTag: (id) => `GET /seoGoogleProjects/${id}`,
    getThankyou: (id) => `GET /seoGoogleThankyous/${id}`,
    updateConfig: () => `PATCH /seoGoogleConfig`,
    saveTag: (id) => `POST /seoGoogleProjects/${id}`,
    saveThankyou: (id) => `POST /seoGoogleThankyous/${id}`
  },
  seoFacebook: {
    getConfig: () => `GET /seoFacebookConfig`,
    getTag: (id) => `GET /seoFacebookProjects/${id}`,
    getThankyou: (id) => `GET /seoFacebookThankyous/${id}`,
    updateConfig: () => `PATCH /seoFacebookConfig`,
    saveTag: (id) => `POST /seoFacebookProjects/${id}`,
    saveThankyou: (id) => `POST /seoFacebookThankyous/${id}`
  },
  icon: {
    list: () => `GET /icons??orderBy=undefined&ascdesc=undefined`
  },
  projectNews: {
    list: ($data) => `GET /projectNews?pid=${$data.pid}&orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    getInfo: ($id) => `GET /projectNews/${$id}`,
    create: () => `POST /projectNews`,
    update: ($id) => `PATCH /projectNews/${$id}`,
    updateSort: () => `POST /projectNews/sort`,
    delete: (id) => `DELETE /projectNews/${id}`
  },
  projectArticles: {
    list: ($data) => `GET /projectArticles?pid=${$data.pid}&orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    getInfo: ($id) => `GET /projectArticles/${$id}`,
    create: () => `POST /projectArticles`,
    update: ($id) => `PATCH /projectArticles/${$id}`,
    updateSort: () => `POST /projectArticles/sort`,
    delete: (id) => `DELETE /projectArticles/${id}`
  },
  campaigns: {
    list: ($data) => `GET /campaigns?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}&type=${$data.type}`,
    getInfo: ($id) => `GET /campaign/${$id}`,
    create: () => `POST /campaign`,
    update: ($id) => `PUT /campaign/${$id}`,
    updatePublish: ($id) => `PATCH /campaign/${$id}/published`,
    updateSort: () => `POST /campaign/sort`,
    delete: (id) => `DELETE /campaign/${id}`
  },
  campaignBanner: {
    list: ($data) => `GET /campaign/${$data.campaignID}/banners?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    getInfo: ($id, $campaignID) => `GET /campaign/${$campaignID}/banner/${$id}`,
    create: ($campaignID) => `POST /campaign/${$campaignID}/banner`,
    update: ($id, $campaignID) => `PUT /campaign/${$campaignID}/banner/${$id}`,
    updateSort: ($id, $campaignID) => `PATCH /campaign/${$campaignID}/banner/${$id}/sort`,
    delete: (id, $campaignID) => `DELETE /campaign/${$campaignID}/banner/${id}`
  },
  campaignProject: {
    list: ($data) => `GET /campaign/${$data.campaignID}/projects?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    getInfo: ($id, $campaignID) => `GET /campaign/${$campaignID}/project/${$id}`,
    create: ($campaignID) => `POST /campaign/${$campaignID}/project`,
    update: ($id, $campaignID) => `PUT /campaign/${$campaignID}/project/${$id}`,
    sort: ($id, $campaignID) => `PATCH /campaign/${$campaignID}/project/${$id}/sort`,
    delete: (id, $campaignID) => `DELETE /campaign/${$campaignID}/project/${id}`
  },
  campaignRegisterProject: {
    list: ($data) => `GET /campaign/${$data.campaignID}/register-projects?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    getInfo: ($id, $campaignID) => `GET /campaign/${$campaignID}/register-project/${$id}`,
    create: ($campaignID) => `POST /campaign/${$campaignID}/register-project`,
    update: ($id, $campaignID) => `PUT /campaign/${$campaignID}/register-project/${$id}`,
    sort: ($id, $campaignID) => `PATCH /campaign/${$campaignID}/register-project/${$id}/sort`,
    delete: (id, $campaignID) => `DELETE /campaign/${$campaignID}/register-project/${id}`
  },
  campaignConcept: {
    list: ($data) => `GET /campaign/${$data.id}/concept?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    create: ($id) => `POST /campaign/${$id}/concept`,
    update: ($id) => `PATCH /campaign/${$id}/concept`
  },
  campaignRegisterSetting: {
    list: ($data) => `GET /campaign/${$data.id}/registerSettings`,
    update: ($data) => `PUT /campaign/${$data.campaign_id}/registerSettings/${$data.id}`
  },
  campaignSeo: {
    fb: {
      content: {
        list: ($data) => `GET /campaign/${$data.id}/seoFacebook`,
        create: ($id) => `POST /campaign/${$id}/seoFacebook`,
        update: ($id) => `PUT /campaign/${$id}/seoFacebook`
      },
      thankyou: {
        list: ($data) => `GET /campaign/${$data.id}/seoFacebook-thankyou`,
        create: ($id) => `POST /campaign/${$id}/seoFacebook-thankyou`,
        update: ($id) => `PUT /campaign/${$id}/seoFacebook-thankyou`
      }
    },
    google: {
      content: {
        list: ($data) => `GET /campaign/${$data.id}/seoGoogle`,
        create: ($id) => `POST /campaign/${$id}/seoGoogle`,
        update: ($id) => `PUT /campaign/${$id}/seoGoogle`
      },
      thankyou: {
        list: ($data) => `GET /campaign/${$data.id}/seoGoogle-thankyou`,
        create: ($id) => `POST /campaign/${$id}/seoGoogle-thankyou`,
        update: ($id) => `PUT /campaign/${$id}/seoGoogle-thankyou`
      }
    }
  },
  campaignTitileThumbnail: {
    info: ($id) => `GET /campaign/${$id}/title_campaign_project`,
    update: ($id) => `PATCH /campaign/${$id}/title_campaign_project`
  },
  config: {
    email: {
      list: ($data) => `GET /contactReceivers?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
      create: () => `POST /contactReceivers`,
      update: ($id) => `PATCH /contactReceivers/${$id}`,
      delete: ($id) => `DELETE /contactReceivers/${$id}`
    },
    subject: {
      list: ($data) => `GET /contactSubjects?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
      create: () => `POST /contactSubjects`,
      update: ($id) => `PATCH /contactSubjects/${$id}`,
      delete: ($id) => `DELETE /contactSubjects/${$id}`
    },
    message: {
      list: ($data) => `GET /contactMails?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}&search=${$data.search}`,
      getInfo: ($id) => `GET /contactMails/${$id}`
    },
    map: {
      list: () => `GET /contactMaps`,
      update: () => `PATCH /contactMaps`
    }
  },
  about: {
    banner: {
      detail: () => `GET /aboutBanner`,
      update: () => `PATCH /aboutBanner`
    },
    branding: {
      detail: () => `GET /aboutBranding`,
      update: () => `PATCH /aboutBranding`
    },
    milestone: {
      list: ($data) => `GET /aboutMilestones?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
      getInfo: ($id) => `GET /aboutMilestones/${$id}`,
      create: () => 'POST /aboutMilestones',
      update: ($id) => `PATCH /aboutMilestones/${$id}`,
      updateSort: () => `POST /aboutMilestones/sort`,
      delete: ($id) => `DELETE /aboutMilestones/${$id}`
    }
  },
  gufamily: {
    category: {
      list: ($data) => `GET /privilegeCategorys?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
      getInfo: ($id) => `GET /privilegeCategorys/${$id}`,
      create: () => `POST /privilegeCategorys`,
      update: ($id) => `PATCH /privilegeCategorys/${$id}`,
      updateSort: () => `POST /privilegeCategorys/sort`,
      delete: ($id) => `DELETE /privilegeCategorys/${$id}`
    },
    privilege: {
      list: ($data) => `GET /privileges?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
      getInfo: ($id) => `GET /privileges/${$id}`,
      create: () => `POST /privileges`,
      update: ($id) => `PATCH /privileges/${$id}`,
      updateSort: () => `POST /privileges/sort`,
      delete: ($id) => `DELETE /privileges/${$id}`
    },
    activity: {
      list: ($data) => `GET /projectActivities?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
      getInfo: ($id) => `GET /projectActivities/${$id}`,
      create: () => `POST /projectActivities`,
      update: ($id) => `PATCH /projectActivities/${$id}`,
      updateSort: () => `POST /projectActivities/sort`,
      delete: ($id) => `DELETE /projectActivities/${$id}`
    },
    banner: {
      list: ($data) => `GET /bannerFamilys?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
      getInfo: ($id) => `GET /bannerFamilys/${$id}`,
      create: () => `POST /bannerFamilys`,
      update: ($id) => `PATCH /bannerFamilys/${$id}`,
      updateSort: () => `POST /bannerFamilys/sort`,
      delete: ($id) => `DELETE /bannerFamilys/${$id}`
    }
  },
  privacy: {
    getInfo: () => `GET /privacy`,
    update: () => `PATCH /privacy`
  },
  term: {
    getInfo: () => `GET /term`,
    update: () => `PATCH /term`
  },
  mobileapp: {
    getInfo: () => `GET /mobile-app-info`,
    update: () => `PATCH /mobile-app-info`
  },
  btsCategory: {
    list: ($data) => `GET /trainCategorys?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    getInfo: ($id) => `GET /trainCategorys/${$id}`,
    create: () => `POST /trainCategorys`,
    update: ($id) => `PATCH /trainCategorys/${$id}`,
    updateSort: () => `POST /trainCategorys/sort`,
    delete: ($id) => `DELETE /trainCategorys/${$id}`
  },
  filemanager: {
    list: ($data) => `GET /file-manager?limit=${$data.limit}&page=${$data.page}&orderBy=${$data.sort}&ascdesc=${$data.order}`,
    create: () => `POST /file-manager`,
    delete: ($id) => `DELETE /file-manager/${$id}`
  },
  bannerContact: {
    getInfo: () => `GET /bannerContacts`,
    update: () => `PATCH /bannerContacts`
  },
  home: {
    getInfo: () => `GET /home`,
    update: () => `PATCH /home`
  },
  promotion: {
    list: ($data) => `GET /promotions?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    getInfo: ($id) => `GET /promotions/${$id}`,
    create: () => `POST /promotions`,
    update: ($id) => `PATCH /promotions/${$id}`,
    updateSort: () => `POST /promotions/sort`,
    delete: ($id) => `DELETE /promotions/${$id}`
  },
  foreignBuyer: {
    getInfo: () => `GET /foreign-buyer-guide`,
    update: () => 'PATCH /foreign-buyer-guide'
  },
  sense: {
    list: () => `GET /senses`
  },
  job: {
    getInfo: () => `GET /job`,
    update: () => 'PATCH /job'
  },
  role: {
    list: ($data) => `GET /role-manager?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    getInfo: ($id) => `GET /role-manager/${$id}`,
    create: () => `POST /role-manager`,
    update: ($id) => `PATCH /role-manager/${$id}`,
    delete: ($id) => `DELETE /role-manager/${$id}`
  },
  user: {
    list: ($data) => `GET /users-manager?orderBy=${$data.orderBy}&ascdesc=${$data.direction}&limit=${$data.limit}&page=${$data.page}`,
    getInfo: ($id) => `GET /users-manager/${$id}`,
    create: () => `POST /users-manager`,
    update: ($id) => `PATCH /users-manager/${$id}`,
    delete: ($id) => `DELETE /users-manager/${$id}`
  },
  sitemap: {
    getInfo: () => `GET /sitemap`,
    update: () => `PATCH /sitemap`
  },
  calculator: {
    getInfo: () => `GET /calculator`,
    update: () => `PATCH /calculator`
  }
}
