import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {

    [ActionFetch.PROJECTS.VIDEO.INDEX]: ({ commit }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.projects.video.index(id))
        .then(res => {
          const data = res.data
          if (data.success) {
            commit(MutationStore.PROJECTS.VIDEO.INDEX, data.data || [])
          }
          commit(MutationView.SET_LOADING_SCREEN, false)
          return data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.PROJECTS.VIDEO.ADD]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.video.add, { ...params, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.PROJECTS.VIDEO.UPDATE]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.video.update(params.id), { ...params.data, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.PROJECTS.VIDEO.DELETE]: ({ commit, rootState }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.projects.video.delete(id), {}, rootState.cmsUser.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          // const data = res.data
          // if (data.success) {
          //   const video = rootState.projects.video
          //   const resp = video.filter(each => {
          //     return each.id !== id
          //   })
          //   commit(MutationStore.PROJECTS.CONTENT.INDEX, resp)
          // }

          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },
    [ActionFetch.PROJECTS.VIDEO.SORT]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.video.sort, { ...params, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    }

  }
}

export default request
