var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:!_vm.active ? 'active' : '',attrs:{"id":"sidebar"}},[_vm._m(0),_c('ul',{staticClass:"list-unstyled components"},[_vm._l((_vm.routes),function(route,index){return [((_vm.userInfo && _vm.userInfo.role.roles[route.role]) || !route.role)?_c('li',{key:index,class:("outer-menu " + (_vm.matchParent(route.path)))},[_c('a',{on:{"click":function($event){return _vm.view(
              route.subRoutes && route.subRoutes.length > 0
                ? route.subRoutes[0]
                : route.path
            )}}},[_c('i',{class:route.icon,staticStyle:{"margin-right":"10px"}}),_vm._v(" "+_vm._s(route.label)+" "),(route.subRoutes && route.subRoutes.length > 0)?_c('i',{staticClass:"far fa-plus",staticStyle:{"float":"right"}}):_vm._e()]),(
            route.subRoutes &&
            route.subRoutes.length > 0 &&
            _vm.matchParent(route.path) === 'active'
          )?_c('ul',{staticClass:"list-unstyled inner-menu",staticStyle:{"font-weight":"500"}},_vm._l((route.subRoutes),function(subRoute,i){return _c('li',{key:i,class:_vm.matchChild(subRoute.path)},[_c('a',{on:{"click":function($event){return _vm.view(subRoute.path)}}},[_vm._v(_vm._s(subRoute.label))])])}),0):_vm._e()]):_vm._e()]}),_vm._m(1)],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-header"},[_c('img',{attrs:{"src":require("./../assets/logo-gu.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"outer-menu"},[_c('a',[_c('i',{staticClass:"fal fa-book",staticStyle:{"margin-right":"10px"}}),_vm._v(" User Manual ")])])}]

export { render, staticRenderFns }