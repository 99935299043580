import Vue from 'vue'
import { SET_MASTERS, SET_FORM_INPUT, UNSET_FORM_INPUT } from './mutations'

const getPrefix = (ref) => {
  switch (ref) {
    case 'project':
      return 'projects'
    case 'campaign':
      return 'campaigns'
    default:
      return ref
  }
}

export default {
  async getMasters ({ commit }) {
    const { data } = await Vue.axios.get('register-form-masters', {
      baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
      }
    })

    commit(SET_MASTERS, data)

    return data
  },
  async getRegisterForm ({ commit }, { ref, refId }) {
    const { data } = await Vue.axios.get(`${getPrefix(ref)}/${refId}/register-forms`, {
      baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
      }
    })

    return data
  },
  async updateRegisterForm (_, { ref, refId, body }) {
    const { data } = await Vue.axios.patch(
      `${getPrefix(ref)}/${refId}/register-forms`,
      body,
      {
        baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
        }
      }
    )

    return data
  },
  async updateOptions (_, { id, body }) {
    const { data } = await Vue.axios.patch(
      `register-forms/${id}/options`,
      body,
      {
        baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
        }
      }
    )

    return data
  },
  setFormInput ({ commit }, formInput) {
    commit(SET_FORM_INPUT, formInput)
  },
  unsetFormInput ({ commit }) {
    commit(UNSET_FORM_INPUT)
  },
  resendCrm (context, id) {
    return Vue.axios.post(
      `registers/${id}/crm`,
      {},
      {
        baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
        }
      }
    )
  },
  resendAllCrm () {
    return Vue.axios.post(
      `registers/crm`,
      {},
      {
        baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
        }
      }
    )
  }
}
