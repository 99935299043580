import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.PROJECTS.REGISTERSETTING.PROJECTLIST]: ({ commit }) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(
        'GET /projects',
        { order_by: 'orderNum', direction: 'asc', limit: 0 },
        null,
        { apiVersion: '/v2' }
      ).then(res => {
        const data = res.data

        if (data.success && data.data) {
          commit(MutationStore.PROJECTS.REGISTERSETTING.PROJECTLIST, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch(($err) => {})
    },

    [ActionFetch.PROJECTS.REGISTERSETTING.LIST]: ({ commit }, pid) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.projects.register.list(pid)).then($res => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.PROJECTS.REGISTERSETTING.LIST, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch(($err) => {})
    },

    [ActionFetch.PROJECTS.REGISTERSETTING.CREATE]: ({ commit, rootState }, pid) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.register.create(pid), {}, user.token).then($res => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.PROJECTS.REGISTERSETTING.CREATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch(($err) => {})
    },

    [ActionFetch.PROJECTS.REGISTERSETTING.UPDATE]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.register.update(params.id), { ...params.data, createdBy: user.id, updatedBy: user.id }, user.token).then($res => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.PROJECTS.REGISTERSETTING.UPDATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch(($err) => {})
    }
  }
}

export default request
