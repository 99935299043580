import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.GUFAMILY.BANNER.LIST]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.gufamily.banner.list($params)).then($res => {
        commit(MutationView.SET_LOADING_SCREEN, false)
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.GUFAMILY.BANNER.LIST, data.data)
        }

        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.GUFAMILY.BANNER.DETAIL]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.gufamily.banner.getInfo($params.id)).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.GUFAMILY.BANNER.DETAIL, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.GUFAMILY.BANNER.CREATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.createdBy = user.id
      $params.updatedBy = user.id

      return AxiosRequest(API.gufamily.banner.create(), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.GUFAMILY.BANNER.CREATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.GUFAMILY.BANNER.UPDATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.updatedBy = user.id

      return AxiosRequest(API.gufamily.banner.update($params.id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.GUFAMILY.BANNER.UPDATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.GUFAMILY.BANNER.UPDATESORT]: ({ commit, rootState }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.updatedBy = user.id

      return AxiosRequest(API.gufamily.banner.updateSort(), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.GUFAMILY.BANNER.UPDATESORT, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)

        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.GUFAMILY.BANNER.DELETE]: ({ commit, rootState }, $id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser || JSON.parse(localStorage.getItem('cmsUser'))

      return AxiosRequest(API.gufamily.banner.delete($id), {}, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.GUFAMILY.BANNER.DELETE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    }
  }
}

export default request
