import aboutBanner from '@/store/modules/aboutBanner'
import aboutBranding from '@/store/modules/aboutBranding'
import aboutMileStone from '@/store/modules/aboutMileStone'
import banner from '@/store/modules/banner'
import bannerContact from '@/store/modules/bannerContact'
import bannerFamily from '@/store/modules/bannerFamily'
import bannerSlide from '@/store/modules/bannerSlide'
import btsCategory from '@/store/modules/btsCategory'
import calculator from '@/store/modules/calculator'
import legacyCampaign from '@/store/modules/campaign.js'
import campaignBanner from '@/store/modules/campaignBanner'
import campaignConcept from '@/store/modules/campaignConcept'
import campaignProject from '@/store/modules/campaignProject'
import campaignRegisterProject from '@/store/modules/campaignRegisterProject'
import campaignRegisterSetting from '@/store/modules/campaignRegisterSetting'
import campaignSeo from '@/store/modules/campaignSeo'
import campaignTitleThumbnail from '@/store/modules/campaignTitleThumbnail'
import config from '@/store/modules/config'
import contactMail from '@/store/modules/contactMail'
import contactMap from '@/store/modules/contactMap'
import contactReceiver from '@/store/modules/contactReceiver'
import contactSubject from '@/store/modules/contactSubject'
import guList from '@/store/modules/external/project'
import filemanager from '@/store/modules/filemanager'
import foreignBuyer from '@/store/modules/foreignBuyer'
import home from '@/store/modules/home'
import icon from '@/store/modules/icon'
import job from '@/store/modules/job'
import location from '@/store/modules/location'
import mobileApp from '@/store/modules/mobileApp'
import price from '@/store/modules/price'
import privacy from '@/store/modules/privacy'
import privilegeCategory from '@/store/modules/privilegeCategory'
import privileges from '@/store/modules/privileges'
import legacyProject from '@/store/modules/project.js'
import projectActivity from '@/store/modules/projectActivity'
import projectAdvanceSearch from '@/store/modules/projectAdvanceSearch'
import projectArticles from '@/store/modules/projectArticle'
import projectAttributes from '@/store/modules/projectAttributes'
import projectBrochure from '@/store/modules/projectBrochure'
import projectCategories from '@/store/modules/projectCategories'
import projectContent from '@/store/modules/projectContent'
import projectFloors from '@/store/modules/projectFloors'
import projectGallery from '@/store/modules/projectGallery'
import projectInfo from '@/store/modules/projectInfo'
import projectLocation from '@/store/modules/projectLocation'
import projectNews from '@/store/modules/projectNews'
import projectPanoramas from '@/store/modules/projectPanoramas'
import projectProgress from '@/store/modules/projectProgress'
import projectPublics from '@/store/modules/projectPublics'
import projectRegister from '@/store/modules/projectRegister'
import projectRegisterSetting from '@/store/modules/projectRegisterSetting'
import projectUnits from '@/store/modules/projectUnits'
import projectVideo from '@/store/modules/projectVideo'
import promotion from '@/store/modules/promotion'
import role from '@/store/modules/role'
import sense from '@/store/modules/sense'
import seoFacebook from '@/store/modules/seoFacebook'
import seoGoogle from '@/store/modules/seoGoogle'
import sitemap from '@/store/modules/sitemap'
import systemConfigs from '@/store/modules/systemConfigs'
import term from '@/store/modules/term'
import transportLink from '@/store/modules/transportLink'
import user from '@/store/modules/user'
import userManager from '@/store/modules/users'
import view from '@/store/modules/view'
import { MutationStore } from '@/store/mutationTypes'
import Vue from 'vue'
import Vuex from 'vuex'
import campaign from './modules/campaign/index.js'
import lead from './modules/lead'
import projects from './modules/project/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    view,
    banner,
    user,
    bannerSlide,
    legacyProject,
    projectPublics,
    projectCategories,
    projectContent,
    projectInfo,
    projectLocation,
    projectGallery,
    projectVideo,
    projectPanoramas,
    projectBrochure,
    projectFloors,
    projectUnits,
    projectProgress,
    projectAttributes,
    systemConfigs,
    projectRegisterSetting,
    projectRegister,
    projectAdvanceSearch,
    transportLink,
    guList,
    location,
    price,
    seoGoogle,
    seoFacebook,
    icon,
    projectNews,
    projectArticles,
    contactReceiver,
    contactSubject,
    contactMail,
    contactMap,
    aboutBanner,
    aboutBranding,
    aboutMileStone,
    privilegeCategory,
    privileges,
    projectActivity,
    bannerFamily,
    privacy,
    term,
    mobileApp,
    btsCategory,
    filemanager,
    bannerContact,
    home,
    promotion,
    foreignBuyer,
    sense,
    job,
    role,
    userManager,
    sitemap,
    calculator,
    legacyCampaign,
    campaignBanner,
    campaignProject,
    campaignRegisterProject,
    campaignConcept,
    campaignRegisterSetting,
    campaignSeo,
    campaignTitleThumbnail,

    lead,
    projects,
    campaign,
    config
  },
  state: {
    cmsUser: JSON.parse(localStorage.getItem('cmsUser'))
  },
  mutations: {
    [MutationStore.SET_USER_AUTHEN]: (state, data) => {
      localStorage.setItem('cmsUser', JSON.stringify(data))
    },

    [MutationStore.BANNERS.INDEX_LIST]: (state, data) => {
      if (!state.banners) {
        state.banners = {}
      }
      state.banners.index = data
    },
    [MutationStore.BANNERS.HOME_LIST]: (state, data) => {
      if (!state.banners) {
        state.banners = {}
      }
      state.banners.home = data
    },
    [MutationStore.PROJECTS.CATEGORIES.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.categories = data
    },
    [MutationStore.PROJECTS.ADVANCESEARCH.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.advanceSearch = data
    },
    [MutationStore.PROJECTS.PUBLICS.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }

      if (!state.project.publics) {
        state.project.publics = {}
      }
      state.project.publics[data.pid] = data.data
    },
    [MutationStore.PROJECTS.MAIN.INDEX]: (state, data) => {
      if (!state.projects) {
        state.projects = {}
      }

      state.projects.main = data
    },
    [MutationStore.PROJECTS.MAIN.CLEAR_PROJECT_LIST]: (state) => {
      if (!state.projects) {
        state.projects = {}
      }
      state.projects.main = null
    },
    [MutationStore.PROJECTS.MAIN.DETAIL]: (state, data) => {
      state.project = {
        detail: data
      }
    },
    [MutationStore.PROJECTS.BANNERSLIDE.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.bannerSlide = data
    },
    [MutationStore.PROJECTS.CONTENT.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.content = data
    },
    [MutationStore.PROJECTS.INFO.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.info = data
    },
    [MutationStore.PROJECTS.LOCATION.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.location = data
    },
    [MutationStore.PROJECTS.GALLERY.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.gallerys = data
    },
    [MutationStore.PROJECTS.VIDEO.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.videos = data
    },
    [MutationStore.PROJECTS.PANORAMAS.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.panoramas = data
    },
    [MutationStore.PROJECTS.BROCHURE.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.brochure = data
    },
    [MutationStore.PROJECTS.FLOORS.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.floors = data.map((floor) => ({
        ...floor,
        attributeId: floor.attribute,
        buildingId: floor.building,
        images: floor.image
      }))
    },
    [MutationStore.PROJECTS.UNITS.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.units = data
    },
    [MutationStore.PROJECTS.PROGRESS.INDEX]: (state, data) => {
      if (!state.project) {
        state.project = {}
      }
      state.project.progress = data
    },
    [MutationStore.ATTRIBUTES.INDEX]: (state, data) => {
      if (!state.attributes) {
        state.attributes = {}
      }
      state.attributes.list = data
    },
    [MutationStore.SYSTEM_CONFIGS.LIST]: (state, data) => {
      if (!state.systemConfigs) {
        state.systemConfigs = {}
      }
      state.systemConfigs[data.moduleName] = data.data
    },
    [MutationStore.PROJECTS.REGISTERSETTING.PROJECTLIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {}
      }

      state.projects.projectlist = data
    },
    [MutationStore.PROJECTS.REGISTERSETTING.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {}
      }

      state.projects.list = data
    },
    [MutationStore.PROJECTS.REGISTERSETTING.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          registerSetting: null
        }
      }

      state.projects.registerSetting = data
    },
    [MutationStore.PROJECTS.REGISTER.SEND_CRM]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          register: null
        }
      }

      state.projects.register = data
    },
    [MutationStore.PROJECTS.REGISTER.SEND_CRM_ALL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          register: null
        }
      }

      state.projects.register = data
    },
    [MutationStore.EXTERNAL.PROJECT_GULEAD.LIST]: (state, data) => {
      if (!state.banners) {
        state.external = {}
      }
      state.external.guleadList = data
    },

    [MutationStore.PROJECTS.REGISTERSETTING.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          registerUpdate: null
        }

        state.projects.registerUpdate = data
      }
    },
    [MutationStore.TRANSPORTLINK.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          transportlink: null
        }
      }

      state.projects.transportlink = data
    },
    [MutationStore.TRANSPORTLINK.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          transportNew: null
        }
      }

      state.projects.transportNew = data
    },
    [MutationStore.TRANSPORTLINK.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          transport: null
        }
      }

      state.projects.transport = data
    },
    [MutationStore.TRANSPORTLINK.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          transport: null
        }
      }

      state.projects.transport = data
    },
    [MutationStore.TRANSPORTLINK.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          transport: null
        }
      }

      state.projects.transport = data
    },
    [MutationStore.SEARCH_LOCATION.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          locationList: null
        }
      }

      state.projects.locationList = data
    },

    [MutationStore.SEARCH_LOCATION.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          location: null
        }
      }

      state.projects.location = data
    },

    [MutationStore.SEARCH_LOCATION.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          location: null
        }
      }

      state.projects.location = data
    },

    [MutationStore.SEARCH_LOCATION.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          location: null
        }
      }

      state.projects.location = data
    },

    [MutationStore.SEARCH_LOCATION.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          location: null
        }
      }

      state.projects.location = data
    },

    [MutationStore.SEARCH_PRICE.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          priceRangeList: null
        }
      }

      state.projects.priceRangeList = data
    },

    [MutationStore.SEARCH_PRICE.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          priceRange: null
        }
      }

      state.projects.priceRange = data
    },

    [MutationStore.SEARCH_PRICE.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          priceRange: null
        }
      }

      state.projects.priceRange = data
    },

    [MutationStore.SEARCH_PRICE.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          priceRange: null
        }
      }

      state.projects.priceRange = data
    },

    [MutationStore.SEARCH_PRICE.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          priceRange: null
        }
      }

      state.projects.priceRange = data
    },

    [MutationStore.SEO_GOOGLE.GET_CONFIG]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoGoogleConfig: null
        }
      }

      state.projects.seoGoogleConfig = data
    },

    [MutationStore.SEO_GOOGLE.GET_TAG]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoGoogleTag: null
        }
      }

      state.projects.seoGoogleTag = data
    },

    [MutationStore.SEO_GOOGLE.GET_THANKYOU]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoThankyou: null
        }
      }

      state.projects.seoThankyou = data
    },

    [MutationStore.SEO_GOOGLE.UPDATE_CONFIG]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoGoogleConfig: null
        }
      }

      state.projects.seoGoogleConfig = data
    },

    [MutationStore.SEO_GOOGLE.SAVE_TAG]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoGoogleTag: null
        }
      }

      state.projects.seoGoogleTag = data
    },

    [MutationStore.SEO_GOOGLE.SAVE_THANKYOU]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoThankyou: null
        }
      }

      state.projects.seoThankyou = data
    },

    [MutationStore.SEO_FACEBOOK.GET_CONFIG]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoFacebookConfig: null
        }
      }

      state.projects.seoFacebookConfig = data
    },

    [MutationStore.SEO_FACEBOOK.GET_TAG]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoFacebookTag: null
        }
      }

      state.projects.seoFacebookTag = data
    },

    [MutationStore.SEO_FACEBOOK.GET_THANKYOU]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoFacebookThankyou: null
        }
      }

      state.projects.seoFacebookThankyou = data
    },

    [MutationStore.SEO_FACEBOOK.UPLOAD]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoFacebookUpload: null
        }
      }

      state.projects.seoFacebookUpload = data
    },

    [MutationStore.SEO_FACEBOOK.SAVE_CONFIG]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoFacebookConfig: null
        }
      }

      state.projects.seoFacebookConfig = data
    },

    [MutationStore.SEO_FACEBOOK.SAVE_TAG]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoFacebookTag: null
        }
      }

      state.projects.seoFacebookTag = data
    },

    [MutationStore.SEO_FACEBOOK.SAVE_THANKYOU]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          seoFacebookTag: null
        }
      }

      state.projects.seoFacebookTag = data
    },

    [MutationStore.ICON.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          icon: null
        }
      }
      state.projects.icon = data
    },

    [MutationStore.PROJECT_NEWS.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          newsList: null
        }
      }

      state.projects.newsList = data
    },

    [MutationStore.PROJECT_NEWS.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          news: null
        }
      }

      state.projects.news = data
    },

    [MutationStore.PROJECT_NEWS.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          news: null
        }

        state.projects.news = data
      }
    },

    [MutationStore.PROJECT_NEWS.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          news: null
        }

        state.projects.news = data
      }
    },

    [MutationStore.PROJECT_NEWS.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          news: null
        }

        state.projects.news = data
      }
    },

    [MutationStore.PROJECT_NEWS.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          news: null
        }
      }

      state.projects.news = data
    },

    [MutationStore.PROJECT_ARTICLE.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          articleList: null
        }
      }

      state.projects.articleList = data
    },

    [MutationStore.PROJECT_ARTICLE.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          article: null
        }
      }

      state.projects.article = data
    },

    [MutationStore.PROJECT_ARTICLE.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          article: null
        }

        state.projects.article = data
      }
    },

    [MutationStore.PROJECT_ARTICLE.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          article: null
        }

        state.projects.article = data
      }
    },

    [MutationStore.PROJECT_ARTICLE.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          article: null
        }

        state.projects.article = data
      }
    },

    [MutationStore.PROJECT_ARTICLE.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          article: null
        }
      }

      state.projects.article = data
    },

    [MutationStore.CONFIG.EMAIL.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          configEmailList: null
        }
      }

      state.projects.configEmailList = data
    },

    [MutationStore.CONFIG.EMAIL.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          configEmail: null
        }
        state.projects.configEmail = data
      }
    },

    [MutationStore.CONFIG.EMAIL.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          configEmail: null
        }
      }

      state.projects.configEmail = data
    },

    [MutationStore.CONFIG.EMAIL.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          configEmail: null
        }
      }

      state.projects.configEmail = data
    },

    [MutationStore.CONFIG.SUBJECT.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          configSubjectList: null
        }
      }

      state.projects.configSubjectList = data
    },

    [MutationStore.CONFIG.SUBJECT.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          configSubject: null
        }
      }

      state.projects.configSubject = data
    },

    [MutationStore.CONFIG.SUBJECT.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          configSubject: null
        }
      }

      state.projects.configSubject = data
    },

    [MutationStore.CONFIG.SUBJECT.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          configSubject: null
        }
      }

      state.projects.configSubject = data
    },

    [MutationStore.CONFIG.MESSAGE.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          messageList: null
        }
      }

      state.projects.messageList = data
    },

    [MutationStore.CONFIG.MESSAGE.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          message: null
        }
      }

      state.projects.message = data
    },

    [MutationStore.CONFIG.MAP.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          contactMap: null
        }
      }

      state.projects.contactMap = data
    },

    [MutationStore.CONFIG.MAP.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          contactMap: null
        }
      }

      state.projects.contactMap = data
    },

    [MutationStore.ABOUT.BANNER.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          aboutBanner: null
        }
      }

      state.projects.aboutBanner = data
    },

    [MutationStore.ABOUT.BANNER.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          aboutBanner: null
        }
      }

      state.projects.aboutBanner = data
    },

    [MutationStore.ABOUT.BRANDING.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          aboutBranding: null
        }
      }

      state.projects.aboutBranding = data
    },

    [MutationStore.ABOUT.BRANDING.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          aboutBranding: null
        }
      }

      state.projects.aboutBranding = data
    },

    [MutationStore.ABOUT.MILESTONE.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          aboutMileStoneList: null
        }
      }

      state.projects.aboutMileStoneList = data
    },

    [MutationStore.ABOUT.MILESTONE.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          aboutMileStone: null
        }
      }

      state.projects.aboutMileStone = data
    },

    [MutationStore.ABOUT.MILESTONE.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          aboutMileStone: null
        }
      }

      state.projects.aboutMileStone = data
    },

    [MutationStore.ABOUT.MILESTONE.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          aboutMileStone: null
        }
      }

      state.projects.aboutMileStone = data
    },

    [MutationStore.ABOUT.MILESTONE.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          aboutMileStone: null
        }
      }

      state.projects.aboutMileStone = data
    },

    [MutationStore.ABOUT.MILESTONE.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          aboutMileStoneSort: null
        }
      }

      state.projects.aboutMileStoneSort = data
    },

    [MutationStore.GUFAMILY.CATEGORY.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyCategoryList: null
        }
      }

      state.projects.gufamilyCategoryList = data
    },

    [MutationStore.GUFAMILY.CATEGORY.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyCategory: null
        }
      }

      state.projects.gufamilyCategory = data
    },

    [MutationStore.GUFAMILY.CATEGORY.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyCategory: null
        }
      }

      state.projects.gufamilyCategory = data
    },

    [MutationStore.GUFAMILY.CATEGORY.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyCategory: null
        }
      }

      state.projects.gufamilyCategory = data
    },

    [MutationStore.GUFAMILY.CATEGORY.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyCategory: null
        }
      }

      state.projects.gufamilyCategory = data
    },

    [MutationStore.GUFAMILY.CATEGORY.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyCategorySort: null
        }
      }

      state.projects.gufamilyCategorySort = data
    },

    [MutationStore.GUFAMILY.PRIVILEGE.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyPrivilegeList: null
        }
      }

      state.projects.gufamilyPrivilegeList = data
    },

    [MutationStore.GUFAMILY.PRIVILEGE.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyPrivilege: null
        }
      }

      state.projects.gufamilyPrivilege = data
    },

    [MutationStore.GUFAMILY.PRIVILEGE.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyPrivilege: null
        }
      }

      state.projects.gufamilyPrivilege = data
    },

    [MutationStore.GUFAMILY.PRIVILEGE.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyPrivilege: null
        }
      }

      state.projects.gufamilyPrivilege = data
    },

    [MutationStore.GUFAMILY.PRIVILEGE.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyPrivilege: null
        }
      }

      state.projects.gufamilyPrivilege = data
    },

    [MutationStore.GUFAMILY.PRIVILEGE.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyPrivilegeSort: null
        }
      }

      state.projects.gufamilyPrivilegeSort = data
    },

    [MutationStore.GUFAMILY.ACTIVITY.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyActivityList: null
        }
      }

      state.projects.gufamilyActivityList = data
    },

    [MutationStore.GUFAMILY.ACTIVITY.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyActivity: null
        }
      }

      state.projects.gufamilyActivity = data
    },

    [MutationStore.GUFAMILY.ACTIVITY.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyActivity: null
        }
      }

      state.projects.gufamilyActivity = data
    },

    [MutationStore.GUFAMILY.ACTIVITY.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyActivity: null
        }
      }

      state.projects.gufamilyActivity = data
    },

    [MutationStore.GUFAMILY.ACTIVITY.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyActivity: null
        }
      }

      state.projects.gufamilyActivity = data
    },

    [MutationStore.GUFAMILY.ACTIVITY.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyActivitySort: null
        }
      }

      state.projects.gufamilyActivitySort = data
    },

    [MutationStore.GUFAMILY.BANNER.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyBannerList: null
        }
      }

      state.projects.gufamilyBannerList = data
    },

    [MutationStore.GUFAMILY.BANNER.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyBanner: null
        }
      }

      state.projects.gufamilyBanner = data
    },

    [MutationStore.GUFAMILY.BANNER.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyBanner: null
        }
      }

      state.projects.gufamilyBanner = data
    },

    [MutationStore.GUFAMILY.BANNER.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyBanner: null
        }
      }

      state.projects.gufamilyBanner = data
    },

    [MutationStore.GUFAMILY.BANNER.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyBanner: null
        }
      }

      state.projects.gufamilyBanner = data
    },

    [MutationStore.GUFAMILY.BANNER.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          gufamilyBannerSort: null
        }
      }

      state.projects.gufamilyBannerSort = data
    },

    [MutationStore.PRIVACY.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          privacy: null
        }
      }

      state.projects.privacy = data
    },

    [MutationStore.PRIVACY.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          privacy: null
        }
      }

      state.projects.privacy = data
    },

    [MutationStore.TERM.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          term: null
        }
      }

      state.projects.term = data
    },

    [MutationStore.TERM.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          term: null
        }
      }

      state.projects.term = data
    },

    [MutationStore.MOBILEAPP.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          mobileapp: null
        }
      }

      state.projects.mobileapp = data
    },

    [MutationStore.MOBILEAPP.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          mobileapp: null
        }
      }

      state.projects.mobileapp = data
    },

    [MutationStore.BTSCATEGORY.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          btsCategoryList: null
        }
      }

      state.projects.btsCategoryList = data
    },

    [MutationStore.BTSCATEGORY.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          btsCategory: null
        }
      }

      state.projects.btsCategory = data
    },

    [MutationStore.BTSCATEGORY.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          btsCategory: null
        }
      }

      state.projects.btsCategory = data
    },

    [MutationStore.BTSCATEGORY.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          btsCategory: null
        }
      }

      state.projects.btsCategory = data
    },

    [MutationStore.BTSCATEGORY.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          btsCategory: null
        }
      }

      state.projects.btsCategory = data
    },

    [MutationStore.BTSCATEGORY.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          btsCategorySort: null
        }
      }

      state.projects.btsCategorySort = data
    },

    [MutationStore.FILEMANAGER.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          filemanagerList: null
        }
      }

      state.projects.filemanagerList = data
    },

    [MutationStore.FILEMANAGER.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          filemanager: null
        }
      }

      state.projects.filemanager = data
    },

    [MutationStore.FILEMANAGER.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          filemanager: null
        }
      }

      state.projects.filemanager = data
    },

    [MutationStore.BANNERCONTACT.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          bannerCategory: null
        }
      }

      state.projects.bannerCategory = data
    },

    [MutationStore.BANNERCONTACT.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          bannerCategory: null
        }
      }

      state.projects.bannerCategory = data
    },

    [MutationStore.HOME.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          home: null
        }
      }

      state.projects.home = data
    },

    [MutationStore.HOME.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          home: null
        }
      }

      state.projects.home = data
    },

    [MutationStore.PROMOTION.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          promotionList: null
        }
      }

      state.projects.promotionList = data
    },

    [MutationStore.PROMOTION.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          promotion: null
        }
      }

      state.projects.promotion = data
    },

    [MutationStore.PROMOTION.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          promotion: null
        }
      }

      state.projects.promotion = data
    },

    [MutationStore.PROMOTION.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          promotion: null
        }
      }

      state.projects.promotion = data
    },

    [MutationStore.PROMOTION.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          promotion: null
        }
      }

      state.projects.promotion = data
    },

    [MutationStore.PROMOTION.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          promotionSort: null
        }
      }

      state.projects.promotionSort = data
    },

    [MutationStore.FOREIGNBUYER.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          foreignBuyer: null
        }
      }

      state.projects.foreignBuyer = data
    },

    [MutationStore.FOREIGNBUYER.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          foreignBuyer: null
        }
      }

      state.projects.foreignBuyer = data
    },

    [MutationStore.SENSE.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          senseList: null
        }
      }

      state.projects.senseList = data
    },

    [MutationStore.JOB.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          job: null
        }
      }

      state.projects.job = data
    },

    [MutationStore.JOB.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          job: null
        }
      }

      state.projects.job = data
    },

    [MutationStore.ROLE.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          roleList: null
        }
      }

      state.projects.roleList = data
    },

    [MutationStore.ROLE.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          role: null
        }
      }

      state.projects.role = data
    },

    [MutationStore.ROLE.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          role: null
        }
      }

      state.projects.role = data
    },

    [MutationStore.ROLE.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          role: null
        }
      }

      state.projects.role = data
    },

    [MutationStore.ROLE.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          role: null
        }
      }

      state.projects.role = data
    },

    [MutationStore.USER.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          userManagerList: null
        }
      }

      state.projects.userManagerList = data
    },

    [MutationStore.USER.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          userManager: null
        }
      }

      state.projects.userManager = data
    },

    [MutationStore.USER.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          userManager: null
        }
      }

      state.projects.userManager = data
    },

    [MutationStore.USER.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          userManager: null
        }
      }

      state.projects.userManager = data
    },

    [MutationStore.USER.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          userManager: null
        }
      }

      state.projects.userManager = data
    },

    [MutationStore.SITEMAP.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          sitemap: null
        }
      }

      state.projects.sitemap = data
    },

    [MutationStore.SITEMAP.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          sitemap: null
        }
      }

      state.projects.sitemap = data
    },

    [MutationStore.CALCULATOR.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          calculator: null
        }
      }

      state.projects.calculator = data
    },

    [MutationStore.CALCULATOR.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          calculator: null
        }
      }

      state.projects.calculator = data
    },

    [MutationStore.CAMPAIGNS.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignsList: null
        }
      }

      state.projects.campaignsList = data
    },

    [MutationStore.CAMPAIGNS.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaigns: null
        }
      }

      state.projects.campaigns = data
    },

    [MutationStore.CAMPAIGNS.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaigns: null
        }

        state.projects.campaigns = data
      }
    },

    [MutationStore.CAMPAIGNS.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaigns: null
        }

        state.projects.campaigns = data
      }
    },

    [MutationStore.CAMPAIGNS.UPDATE_PUBLISH]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaigns: null
        }

        state.projects.campaigns = data
      }
    },

    [MutationStore.CAMPAIGNS.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaigns: null
        }

        state.projects.campaigns = data
      }
    },

    [MutationStore.CAMPAIGNS.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaigns: null
        }
      }

      state.projects.campaigns = data
    },

    [MutationStore.CAMPAIGNBANNER.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignBannerList: null
        }
      }

      state.projects.campaignBannerList = data
    },

    [MutationStore.CAMPAIGNBANNER.DETAIL]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignBanner: null
        }
      }

      state.projects.campaignBanner = data
    },

    [MutationStore.CAMPAIGNBANNER.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignBanner: null
        }

        state.projects.campaignBanner = data
      }
    },

    [MutationStore.CAMPAIGNBANNER.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignBanner: null
        }

        state.projects.campaignBanner = data
      }
    },

    [MutationStore.CAMPAIGNBANNER.UPDATE_PUBLISH]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignBanner: null
        }

        state.projects.campaignBanner = data
      }
    },

    [MutationStore.CAMPAIGNBANNER.UPDATESORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignBanner: null
        }

        state.projects.campaignBanner = data
      }
    },

    [MutationStore.CAMPAIGNBANNER.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignBanner: null
        }
      }

      state.projects.campaignBanner = data
    },

    [MutationStore.CAMPAIGNPROJECT.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignProjectList: null
        }
      }

      state.projects.campaignProjectList = data
    },

    [MutationStore.CAMPAIGNPROJECT.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignProject: null
        }

        state.projects.campaignProject = data
      }
    },

    [MutationStore.CAMPAIGNPROJECT.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignProject: null
        }

        state.projects.campaignProject = data
      }
    },

    [MutationStore.CAMPAIGNPROJECT.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignProject: null
        }

        state.projects.campaignProject = data
      }
    },

    [MutationStore.CAMPAIGNPROJECT.SORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignProject: null
        }

        state.projects.campaignProject = data
      }
    },

    [MutationStore.CAMPAIGNREGISTERPROJECT.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignRegisterProjectList: null
        }
      }

      state.projects.campaignRegisterProjectList = data
    },

    [MutationStore.CAMPAIGNREGISTERPROJECT.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignRegisterProject: null
        }

        state.projects.campaignRegisterProject = data
      }
    },

    [MutationStore.CAMPAIGNREGISTERPROJECT.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignRegisterProject: null
        }

        state.projects.campaignRegisterProject = data
      }
    },

    [MutationStore.CAMPAIGNREGISTERPROJECT.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignRegisterProject: null
        }

        state.projects.campaignRegisterProject = data
      }
    },

    [MutationStore.CAMPAIGNREGISTERPROJECT.SORT]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignRegisterProject: null
        }

        state.projects.campaignRegisterProject = data
      }
    },

    [MutationStore.CAMPAIGNREGISTERSETTING.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignRegisterSetting: null
        }
      }

      state.projects.campaignRegisterSetting = data
    },

    [MutationStore.CAMPAIGNREGISTERSETTING.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignRegisterSetting: null
        }

        state.projects.campaignRegisterSetting = data
      }
    },

    [MutationStore.CAMPAIGNCONCEPT.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignConceptList: null
        }
      }

      state.projects.campaignConceptList = data
    },

    [MutationStore.CAMPAIGNCONCEPT.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignConcept: null
        }

        state.projects.campaignConcept = data
      }
    },

    [MutationStore.CAMPAIGNCONCEPT.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignConcept: null
        }

        state.projects.campaignConcept = data
      }
    },

    [MutationStore.CAMPAIGNCONCEPT.DELETE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignConcept: null
        }

        state.projects.campaignConcept = data
      }
    },

    [MutationStore.CAMPAIGNSEO.CONTENT.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoContentList: null
        }
      }

      state.projects.campaignSeoContentList = data
    },

    [MutationStore.CAMPAIGNSEO.CONTENT.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoContent: null
        }

        state.projects.campaignSeoContent = data
      }
    },

    [MutationStore.CAMPAIGNSEO.CONTENT.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoContent: null
        }

        state.projects.campaignSeoContent = data
      }
    },

    [MutationStore.CAMPAIGNSEO.THANKYOU.LIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoThankyouList: null
        }
      }

      state.projects.campaignSeoThankyouList = data
    },

    [MutationStore.CAMPAIGNSEO.THANKYOU.CREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoThankyou: null
        }

        state.projects.campaignSeoThankyou = data
      }
    },

    [MutationStore.CAMPAIGNSEO.THANKYOU.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoThankyou: null
        }

        state.projects.campaignSeoThankyou = data
      }
    },

    [MutationStore.CAMPAIGNSEO.CONTENT.FBLIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoContentFbList: null
        }
      }

      state.projects.campaignSeoContentFbList = data
    },

    [MutationStore.CAMPAIGNSEO.CONTENT.FBCREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoContentFb: null
        }

        state.projects.campaignSeoContentFb = data
      }
    },

    [MutationStore.CAMPAIGNSEO.CONTENT.FBUPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoContentFb: null
        }

        state.projects.campaignSeoContentFb = data
      }
    },

    [MutationStore.CAMPAIGNSEO.THANKYOU.FBLIST]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoThankyouFbList: null
        }
      }

      state.projects.campaignSeoThankyouFbList = data
    },

    [MutationStore.CAMPAIGNSEO.THANKYOU.FBCREATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoThankyouFb: null
        }

        state.projects.campaignSeoThankyouFb = data
      }
    },

    [MutationStore.CAMPAIGNSEO.THANKYOU.FBUPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignSeoThankyouFb: null
        }

        state.projects.campaignSeoThankyouFb = data
      }
    },

    [MutationStore.CAMPAIGNTITLETHUMBNAIL.INFO]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignTitleThumbnail: null
        }
      }

      state.projects.campaignTitleThumbnail = data
    },

    [MutationStore.CAMPAIGNTITLETHUMBNAIL.UPDATE]: (state, data) => {
      if (!state.projects) {
        state.projects = {
          campaignTitleThumbnail: null
        }

        state.projects.campaignTitleThumbnail = data
      }
    }
  },
  actions: {

  }
})
