import Vue from 'vue'
import { SET_PROJECTS, SET_PROJECT_MASTERS } from './mutations'

export default {
  async getProjects ({ commit }) {
    const { data } = await Vue.axios.get('projects', {
      params: {
        pagination: false
      },
      baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
      }
    })

    commit(SET_PROJECTS, data)

    return data
  },
  async getProjectMasters ({ commit }) {
    const { data } = await Vue.axios.get('project-masters', {
      baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
      }
    })

    commit(SET_PROJECT_MASTERS, data)

    return data
  },
  async updateProjectField (_, { id, field, value }) {
    const { data } = await Vue.axios.patch(`projects/${id}`, [
      {
        op: 'replace',
        path: `/${field}`,
        value
      }
    ],
    {
      baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
      }
    })

    return data
  }
}
