import Vue from 'vue'
import Router from 'vue-router'

import Welcome from './views/Welcome.page.vue'
import Login from './views/auth/Login.vue'
Vue.use(Router)

const isNotAuthentication = (to, from, next) => {
  const cmsUser = JSON.parse(localStorage.getItem('cmsUser'))
  if (cmsUser && cmsUser.token) {
    next({ path: `/` })
  } else {
    next()
  }
}

const validateRole = (to, from, next, role) => {
  const cmsUser = JSON.parse(localStorage.getItem('cmsUser'))
  if (cmsUser.role.roles[role]) {
    next()
  } else {
    next({ name: 'welcome' })
  }
}

let routes = [
  {
    path: '/',
    name: 'welcome',
    component: Welcome
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      public: true
    },
    beforeEnter: isNotAuthentication
  },
  // start - register pages
  {
    path: '/register/setting',
    name: 'registerSetting',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'register'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/register/RegisterSetting.page.vue')
  },
  {
    path: '/register/brand-setting',
    name: 'registerSettingBrand',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'register'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/register/RegisterSettingBrand.page.vue')
  },
  {
    path: '/register/campaign-setting',
    name: 'registerSettingCampaign',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'register'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/register/RegisterSettingCampaign.page.vue')
  },
  {
    path: '/register/registered-cover',
    name: 'registerListCover',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'register'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/register/RegisterListCover.page.vue')
  },
  {
    path: '/register/registered-project',
    name: 'registerListProject',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'register'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/register/RegisterListProject.page.vue')
  },

  {
    path: '/register/registered-promotion',
    name: 'registerListPromotion',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'register'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/register/RegisterListPromotion.page.vue')
  },
  {
    path: '/cover/index',
    name: 'bannerIndex',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'cover_page'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/banner/BannerIndex.page.vue')
  },
  {
    path: '/banner/home',
    name: 'bannerHome',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'home_page'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/banner/BannerHome.page.vue')
  },
  {
    path: '/banner/guconcept',
    name: 'homeGuConcept',
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/home/GuConcept.page.vue')
  },
  // end - banner pages
  // start - project pages
  {
    path: '/project/sub-domain',
    name: 'projectSubDomain',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'project'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/project/SubDomain.page.vue')
  },
  {
    path: '/project/sub-domain/:id/edit',
    name: 'projectSubDomain',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'project'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/project/EditSubDomain.page.vue')
  },
  {
    path: '/project/brand-campaign',
    name: 'projectBrandCampaign',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'project'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/project/BrandCampaign.page.vue')
  },
  {
    path: '/project/brand-campaign/:id/edit',
    name: 'projectBrandCampaign',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'project'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/project/EditBrandCampaign.page.vue')
  },
  {
    path: '/project/project',
    name: 'projectProject',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'project'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/project/Project.page.vue')
  },
  {
    path: '/project/project/:id/edit',
    name: 'projectSubDomain',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'project'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/project/EditProject.page.vue')
  },
  {
    path: '/project/attributes',
    name: 'projectAttributes',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'project'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/project/Attributes.page.vue')
  },
  // end - project pages

  // start - advance search pages
  {
    path: '/advancesearch/transportlink',
    name: 'transportlink',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'advance_search'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/advancesearch/TransportLink.page.vue')
  },
  {
    path: '/advancesearch/location',
    name: 'location',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'advance_search'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/advancesearch/Location.page.vue')
  },
  {
    path: '/advancesearch/pricerange',
    name: 'pricerange',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'advance_search'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/advancesearch/PriceRange.page.vue')
  },
  {
    path: '/advancesearch/colorline',
    name: 'colorline',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'advance_search'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/advancesearch/ConfigColorLine.page.vue')
  },
  // end - advance search pages

  // start - news / articles
  {
    path: '/newsandarticles/news',
    name: 'News',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'news_article'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/newsandarticles/News.page.vue')
  },
  {
    path: '/newsandarticles/articles',
    name: 'Articles',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'news_article'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/newsandarticles/Articles.page.vue')
  },
  {
    path: '/newsandarticles/blog',
    name: 'Blog',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'news_article'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/newsandarticles/Blog.page.vue')
  },
  {
    path: '/newsandarticles/news/:id/edit',
    name: 'NewsEdit',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'news_article'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/newsandarticles/EditNews.page.vue')
  },
  {
    path: '/newsandarticles/articles/:id/edit',
    name: 'ArticleEdit',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'news_article'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/newsandarticles/EditArticle.page.vue')
  },

  // start - promotion
  {
    path: '/promotion',
    name: 'Promotion',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'promotion'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/promotion/Promotion.page.vue')
  },
  {
    path: '/promotion/:id/edit',
    name: 'PromotionEdit',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'promotion'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/promotion/EditPromotion.page.vue')
  },

  // start - contact
  {
    path: '/contact/banner',
    name: 'Config',
    exact: true,
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'contact'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/contact/SettingBanner.page.vue')
  },
  {
    path: '/contact/receiver',
    name: 'Config',
    exact: true,
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'contact'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/contact/Config.receiver.page.vue')
  },
  {
    path: '/contact/subject',
    name: 'Subject',
    exact: true,
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'contact'),
    component: () => import('./views/contact/Config.subject.page.vue')
  },
  {
    path: '/contact/message',
    name: 'Message',
    exact: true,
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'contact'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/contact/Message.page.vue')
  },
  {
    path: '/contact/map',
    name: 'Map',
    exact: true,
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'contact'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/contact/Map.page.vue')
  },
  {
    path: '/contact/message/:id/view',
    name: 'MessageEdit',
    exact: true,
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'contact'),
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/contact/ViewMessage.page.vue')
  },

  // start - seo pages
  {
    path: '/seo/facebook',
    name: 'facebookSEO',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'seo'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/seo/facebook.page.vue')
  },
  {
    path: '/seo/google',
    name: 'googleSEO',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'seo'),
    exact: true,
    component: () =>
      import(/* webpackChunkName: "RegisterSetting" */ './views/seo/google.page.vue')
  },
  // end - seo pages
  // start - family pages
  {
    path: '/gu-family/banner',
    name: 'FamilyBanner',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'grand_u_fam'),
    component: () => import(/* webpackChunkName: "about" */ './views/gufamily/SettingBanner.page.vue')
  },
  {
    path: '/gu-family/privilege',
    name: 'Privilege',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'grand_u_fam'),
    component: () => import(/* webpackChunkName: "about" */ './views/gufamily/Privilege.page.vue')
  },
  {
    path: '/gu-family/privilege/:id/edit',
    name: 'PrivilegeEdit',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'grand_u_fam'),
    component: () => import(/* webpackChunkName: "about" */ './views/gufamily/EditPrivilege.page.vue')
  },
  {
    path: '/gu-family/activity',
    name: 'UpcomingActivity',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'grand_u_fam'),
    component: () => import(/* webpackChunkName: "about" */ './views/gufamily/UpcomingActivity.page.vue')
  },
  {
    path: '/gu-family/activity/:id/edit',
    name: 'UpcomingActivityEdit',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'grand_u_fam'),
    component: () => import(/* webpackChunkName: "about" */ './views/gufamily/EditUpcomingActivity.page.vue')
  },
  {
    path: '/gu-family/category-privilege',
    name: 'CategoryPrivilege',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'grand_u_fam'),
    component: () => import(/* webpackChunkName: "about" */ './views/gufamily/CategoryPrivilege.page.vue')
  },
  // end - family pages
  // start - about pages
  {
    path: '/about/banner',
    name: 'Banner',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'about'),
    component: () => import(/* webpackChunkName: "about" */ './views/about/SettingBanner.page.vue')
  },
  {
    path: '/about/branding',
    name: 'Branding',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'about'),
    component: () => import(/* webpackChunkName: "about" */ './views/about/Branding.page.vue')
  },
  {
    path: '/about/milestone',
    name: 'Milestone',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'about'),
    component: () => import(/* webpackChunkName: "about" */ './views/about/Milestone.page.vue')
  },
  {
    path: '/about/milestone/:id/edit',
    name: 'MilestoneEdit',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'about'),
    component: () => import(/* webpackChunkName: "about" */ './views/about/EditMilestone.page.vue')
  },
  // end - about pages
  {
    path: '/job',
    name: 'job',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'job'),
    component: () => import(/* webpackChunkName: "about" */ './views/job/Job.vue')
  },
  {
    path: '/role/role',
    name: 'role',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'user_role_manager'),
    component: () => import(/* webpackChunkName: "about" */ './views/role/Role.vue')
  },
  {
    path: '/role/role/:id/edit',
    name: 'RoleEdit',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'user_role_manager'),
    component: () => import('./views/role/RoleEdit.vue')
  },
  {
    path: '/role/user',
    name: 'user',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'user_role_manager'),
    component: () => import(/* webpackChunkName: "about" */ './views/role/User.vue')
  },
  {
    path: '/role/user/:id/edit',
    name: 'user',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'user_role_manager'),
    component: () => import(/* webpackChunkName: "about" */ './views/role/UserEdit.vue')
  },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: () => import(/* webpackChunkName: "about" */ './views/contact/Contact.vue')
  // },
  {
    path: '/projectthumbnail',
    name: 'projectthumbnail',
    component: () => import(/* webpackChunkName: "about" */ './views/projectthumbnail/ProjectThumbNail.vue')
  },
  // start - mobile app
  {
    path: '/mobile-app',
    name: 'mobileapp',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'mobile_app'),
    component: () => import(/* webpackChunkName: "about" */ './views/mobileapp/EditApp.page.vue')
  },
  // end - mobile app
  // start - terms
  {
    path: '/terms',
    name: 'terms',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'terms_conditions'),
    component: () => import(/* webpackChunkName: "about" */ './views/terms/EditTerms.page.vue')
  },
  // end - terms
  {
    path: '/foreign',
    name: 'foreign',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'foreign_buyer_guide'),
    component: () => import(/* webpackChunkName: "about" */ './views/foreign/ForeignList.page.vue')
  },
  {
    path: '/hotdeal',
    name: 'hotdeal',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'config'),
    component: () => import(/* webpackChunkName: "hotdeal" */ './views/hotdeal/Hotdeal.page.vue')
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'sitemap'),
    component: () => import(/* webpackChunkName: "about" */ './views/sitemap/Sitemap.page.vue')
  },
  {
    path: '/calculator',
    name: 'calculator',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'calculator'),
    component: () => import(/* webpackChunkName: "about" */ './views/calculator/Calculator.page.vue')
  },
  // start - file manager
  {
    path: '/filemanager',
    name: 'fileManager',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'file_manager'),
    component: () => import(/* webpackChunkName: "about" */ './views/filemanager/UploadFile.page.vue')
  },
  // end - file manager
  // start - privacy
  {
    path: '/privacy',
    name: 'privacy',
    beforeEnter: (to, form, next) => validateRole(to, form, next, 'privacy'),
    component: () => import(/* webpackChunkName: "about" */ './views/privacy/EditPrivacy.page.vue')
  }
  // end - privacy
]

routes = routes.map(r => {
  if (r.meta && r.meta.public) {
  } else {
    r.meta = r.meta ? { ...r.meta, requiresAuth: true } : { requiresAuth: true }
  }
  return r
})

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('cmsUser') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
