<template>
  <div>
    <b-navbar
      toggleable="lg"
      type="light"
      class="nav-bar"
      style="background-color: rgb(29, 55, 106)"
    >
      <b-button
        size="lg"
        style="color: white"
        variant="default"
        @click="toggleSideBar"
      >
        <i class="fal fa-bars"></i>
      </b-button>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-btn class="title">Welcome : {{ getUserDisplayName() }}</b-btn>
        <b-button size="sm" variant="light" class="mx-1" @click="logout"
          >Log Out</b-button
        >
        <b-button size="sm" variant="light" class="mx-1"
          >Go to website</b-button
        >
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto2">
        <b-btn class="title">Grand U Welcome Home</b-btn>
        <!-- <button  class="right-btn btncolor"><i class="fas fa-sign-out-alt"></i></button>
        -->

        <!--
      <b-row>
         <b-col>
            <div class="round-button">
         <div class="round-button-circle">
            <a href="#" class="round-button">
                <i class="fas fa-sign-out-alt"></i>
            </a>
        </div>
      </div>

         </b-col>

        </b-row>-->
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  props: ['toggleSideBar'],
  methods: {
    async logout () {
      await localStorage.removeItem('cmsUser')
      this.$router.push('/login')
    },
    getUserDisplayName () {
      const cmsUser = JSON.parse(localStorage.getItem('cmsUser'))

      if (cmsUser) {
        return cmsUser.name
      }

      return ''
    }
  }
}
</script>

<style scoped>
.ml-auto2 {
  display: none;
}
/* .btncolor{
  background-color: rgb(252,177,67);
  border: 0px solid black;
  font-weight: 700;
} */
.title {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
}
/* .right-btn {
  margin-left: 5px;
  margin-right: 5px;
  width: 120px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
} */
.nav-bar {
  background: #fff;
  border-bottom: 1.5px solid #dbdbdb;
}

@media (max-width: 768px) {
  .ml-auto {
    display: none;
  }
  .ml-auto2 {
    /* text-align:center;
    margin: auto; */
    display: inline;
  }
  .ml-auto2 .title {
    font-weight: 700;
    font-size: 18px;
  }
}
/*
.round-button {
  width: 45%;
  display: block;
}
.round-button-circle {
  width: 100%;
  display: inline-block;
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;
  background: rgb(252, 177, 67);
}
.round-button-circle:hover {
  background: rgb(252, 177, 67);
}
.round-button a {
  display: block;
  float: right;
  width: 100%;
  padding-top: 50%;
  padding-bottom: 50%;
  line-height: 1em;
  margin-top: -0.5em;

  text-align: center;
  color: #e2eaf3;
  font-family: Verdana;
  font-size: 11px;
  font-weight: bold;
  text-decoration: none;
} */
</style>
