import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {

    [ActionFetch.SYSTEM_CONFIGS.LIST]: ({ commit }, moduleName) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.systemConfigs.list(moduleName))
        .then(res => {
          const data = res.data
          if (data.success && data.data) {
            commit(MutationStore.SYSTEM_CONFIGS.LIST, { moduleName, data: data.data })
          }
          commit(MutationView.SET_LOADING_SCREEN, false)
          return data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.SYSTEM_CONFIGS.UPDATE]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.systemConfigs.update, { ...params, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    }
  }
}

export default request
