<template>
  <div id="welcome-page">
    <div class="page-name">
      <div class="page-title">
        <span class="text-bold">Grand Unity</span> CMS
        <p>
          Hello, what's going on now... let's try update our website Woohoo!
        </p>
      </div>
    </div>
    <div class="page-container menus-container row mx-0">
      <template v-for="(ls, index) in menuList">
        <div
          v-if="(userInfo && userInfo.role.roles[ls.role]) || !ls.role"
          :key="index"
          class="col-md-3"
        >
          <div class="menu-item" @click="goTo(ls.link)">
            <i :class="`fa ${ls.icon}`"></i>
            <p>{{ ls.name }}</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    menuList: [
      {
        icon: 'fa-picture-o',
        name: 'Cover Page',
        path: '/cover',
        link: '/cover/index',
        role: 'cover_page'
      },
      {
        icon: 'fa-picture-o',
        name: 'Home Page',
        path: '/banner',
        link: '/banner/home',
        role: 'home_page'
      },
      {
        icon: 'fa-building-o',
        name: 'Project',
        path: '/project',
        link: '/project/sub-domain',
        role: 'project'
      },
      {
        icon: 'fa-inbox',
        name: 'Register',
        path: '/register',
        link: '/register/setting',
        role: 'register'
      },
      {
        icon: 'fa-globe-asia',
        name: 'SEO',
        path: '/seo',
        role: 'seo',
        link: '/seo/google'
      },
      {
        icon: 'fa-search',
        name: 'Advance Search',
        path: '/advancesearch',
        role: 'advance_search',
        link: '/advancesearch/transportlink'
      },
      {
        icon: 'fa-newspaper',
        name: 'News / Articles',
        path: '/newsandarticles',
        role: 'news_article',
        link: '/newsandarticles/news'
      },
      {
        icon: 'fa-newspaper',
        name: 'Promotion',
        path: '/promotion',
        role: 'promotion',
        link: '/promotion'
      },
      {
        icon: 'fa-users',
        name: 'Grand Unity Family',
        path: '/gu-family',
        role: 'grand_u_fam',
        link: '/gu-family/banner'
      },
      {
        icon: 'fa-users',
        name: 'Join Us',
        path: '/job',
        role: 'job',
        link: '/job'
      },
      {
        icon: 'fa-info-circle',
        name: 'About',
        path: '/about',
        role: 'about',
        link: '/about/banner'
      },
      {
        icon: 'fa-gift',
        name: 'Contact',
        path: '/contact',
        role: 'contact',
        link: '/contact/banner'
      },
      {
        icon: 'fa-mobile-alt',
        name: 'Mobile App',
        path: '/mobile-app',
        role: 'mobile_app',
        link: '/mobile-app'
      },
      {
        icon: 'fa-file-alt',
        name: 'Calculator',
        path: '/calculator',
        role: 'calculator',
        link: '/calculator'
      },
      {
        icon: 'fa-address-book',
        name: 'Foreign Buyer Guide',
        path: '/foreign',
        role: 'foreign_buyer_guide',
        link: '/foreign'
      },
      {
        icon: 'fa-address-book',
        name: 'Sitemap',
        path: '/sitemap',
        role: 'sitemap',
        link: '/sitemap'
      },
      {
        icon: 'fa-file-alt',
        name: 'Terms & Conditions',
        path: '/terms',
        role: 'terms_conditions',
        link: '/terms'
      },
      {
        icon: 'fa-lock',
        name: 'Privacy',
        path: '/privacy',
        role: 'privacy',
        link: '/privacy'
      },
      {
        icon: 'fa-file-alt',
        name: 'File Manager Tools',
        path: '/filemanager',
        role: 'file_manager',
        link: '/filemanager'
      },
      {
        icon: 'fa-users',
        name: 'User & Role Manager',
        path: '/role',
        role: 'user_role_manager',
        link: '/role/user'
      },
      {
        icon: 'fa-book',
        name: 'User Manual',
        path: '',
        link: ''
      }
    ],
    userInfo: {}
  }),
  methods: {
    goTo ($url) {
      this.$router.push($url)
    },
    getUserList () {
      const userStore = localStorage.getItem('cmsUser')

      if (userStore) {
        this.userInfo = JSON.parse(userStore)
      }
    }
  },
  created () {
    this.getUserList()
  }
}
</script>
<style>
.page-title p {
  margin: 0;
}
.menu-item {
  background-color: #ffffff;
  border-radius: 20px;
  text-align: center;
  padding: 40px 10px;
  cursor: pointer;
  transition: all 0.8s;
}
.menu-item.active,
.menu-item:hover {
  transition: all 0.8s;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.4);
  background-color: rgb(29, 55, 106);
  color: #ffffff;
}
.menu-item.active p,
.menu-item:hover p {
  color: #ffffff;
}
.menus-container > div {
  margin-bottom: 30px;
}
.menu-item i {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.menu-item p {
  color: #191919;
  margin: 0;
  font-size: 1rem;
  text-transform: uppercase;
}
.text-bold {
  font-weight: bold;
}
</style>
