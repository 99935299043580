import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.FILEMANAGER.LIST]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.filemanager.list($params)).then($res => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.FILEMANAGER.LIST, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.FILEMANAGER.CREATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.createdBy = user.id
      $params.updatedBy = user.id

      return AxiosRequest(API.filemanager.create(), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.FILEMANAGER.CREATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.FILEMANAGER.DELETE]: ({ commit, rootState }, $id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser || JSON.parse(localStorage.getItem('cmsUser'))

      return AxiosRequest(API.filemanager.delete($id), {}, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.FILEMANAGER.DELETE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    }
  }
}

export default request
