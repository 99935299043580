import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {

    [ActionFetch.BANNERS_LIST]: ({ commit }, type) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.banners.indexList(type))
        .then(res => {
          const data = res.data
          if (data.success && data.data) {
            if (type === 'index') {
              commit(MutationStore.BANNERS.INDEX_LIST, data.data)
            } else {
              commit(MutationStore.BANNERS.HOME_LIST, data.data)
            }
          }
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
        .catch((error) => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
        })
    },

    [ActionFetch.BANNERS_DETAIL]: ({ dispatch, commit, rootState }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.banners.detail(id), {}, rootState.cmsUser.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
        })
    },

    [ActionFetch.BANNERS_ADD]: ({ dispatch, commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.banners.add, { ...params, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.BANNERS_UPDATE]: ({ dispatch, commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.banners.update(params.id), { ...params.data, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.BANNERS_DELETE]: ({ dispatch, commit, rootState }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.banners.delete(id), {}, rootState.cmsUser.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },
    [ActionFetch.BANNERS_SORT]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.banners.sort, { ...params, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    }

  }
}

export default request
