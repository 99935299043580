import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.CAMPAIGNREGISTERPROJECT.LIST]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.campaignRegisterProject.list($params)).then($res => {
        const data = $res.data
        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNREGISTERPROJECT.LIST, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNREGISTERPROJECT.CREATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.createdBy = user.id
      $params.updatedBy = user.id

      return AxiosRequest(API.campaignRegisterProject.create($params.campaign_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNREGISTERPROJECT.CREATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNREGISTERPROJECT.UPDATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.updatedBy = user.id

      return AxiosRequest(API.campaignRegisterProject.update($params.id, $params.campaign_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNREGISTERPROJECT.UPDATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNREGISTERPROJECT.DELETE]: ({ commit, rootState }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser || JSON.parse(localStorage.getItem('cmsUser'))

      return AxiosRequest(API.campaignRegisterProject.delete($params.id, $params.campaign_id), {}, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNREGISTERPROJECT.DELETE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.CAMPAIGNREGISTERPROJECT.SORT]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.updatedBy = user.id

      return AxiosRequest(API.campaignRegisterProject.sort($params.id, $params.campaign_id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.CAMPAIGNREGISTERPROJECT.SORT, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    }
  }
}

export default request
