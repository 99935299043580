import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.SEO_GOOGLE.GET_CONFIG]: ({ commit }) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.seoGoogle.getConfig()).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEO_GOOGLE.GET_CONFIG, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.SEO_GOOGLE.GET_TAG]: ({ commit }, $id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.seoGoogle.getTag($id)).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEO_GOOGLE.GET_TAG, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.SEO_GOOGLE.GET_THANKYOU]: ({ commit }, $id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.seoGoogle.getThankyou($id)).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEO_GOOGLE.GET_THANKYOU, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.SEO_GOOGLE.UPDATE_CONFIG]: ({ commit, rootState }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser

      $params.updatedBy = user.id

      return AxiosRequest(API.seoGoogle.updateConfig(), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEO_GOOGLE.GET_CONFIG, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.SEO_GOOGLE.SAVE_TAG]: ({ commit, rootState }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser
      const project = $params.project

      $params.updatedBy = user.id

      delete $params.project

      return AxiosRequest(API.seoGoogle.saveTag(project), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEO_GOOGLE.SAVE_TAG, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.SEO_GOOGLE.SAVE_THANKYOU]: ({ commit, rootState }, $params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      const user = rootState.cmsUser
      const project = $params.project

      $params.updatedBy = user.id

      delete $params.project

      return AxiosRequest(API.seoGoogle.saveThankyou(project), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.SEO_GOOGLE.SAVE_THANKYOU, data.data)
        }

        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    }
  }
}

export default request
