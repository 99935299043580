<template>
  <nav id="sidebar" :class="!active ? 'active' : ''">
    <div class="sidebar-header">
      <img src="./../assets/logo-gu.svg" />
    </div>
    <ul class="list-unstyled components">
      <template v-for="(route, index) in routes">
        <li
          v-if="(userInfo && userInfo.role.roles[route.role]) || !route.role"
          :key="index"
          :class="`outer-menu ${matchParent(route.path)}`"
        >
          <a
            @click="
              view(
                route.subRoutes && route.subRoutes.length > 0
                  ? route.subRoutes[0]
                  : route.path
              )
            "
          >
            <i :class="route.icon" style="margin-right: 10px"></i>
            {{ route.label }}
            <i
              v-if="route.subRoutes && route.subRoutes.length > 0"
              style="float: right"
              class="far fa-plus"
            ></i>
          </a>
          <ul
            class="list-unstyled inner-menu"
            style="font-weight: 500"
            v-if="
              route.subRoutes &&
              route.subRoutes.length > 0 &&
              matchParent(route.path) === 'active'
            "
          >
            <li
              v-for="(subRoute, i) in route.subRoutes"
              :key="i"
              :class="matchChild(subRoute.path)"
            >
              <a @click="view(subRoute.path)">{{ subRoute.label }}</a>
            </li>
          </ul>
        </li>
      </template>
      <li class="outer-menu">
        <a>
          <i class="fal fa-book" style="margin-right: 10px"></i>
          User Manual
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ['active', 'toggleSideBar'],
  data: () => ({
    userInfo: {},
    routes: [
      { label: 'Welcome', path: '/', icon: 'fal fa-compass' },
      {
        label: 'Cover page',
        path: '/cover',
        icon: 'fal fa-image',
        role: 'cover_page',
        subRoutes: [
          {
            label: 'Banner',
            path: '/cover/index'
          }
        ]
      },
      {
        label: 'Home page',
        path: '/banner',
        icon: 'fal fa-image',
        role: 'home_page',
        subRoutes: [
          {
            label: 'Banner',
            path: '/banner/home'
          },
          {
            label: 'GrandU Concept',
            path: '/banner/guconcept'
          }
        ]
      },

      {
        label: 'Project',
        path: '/project',
        icon: 'fal fa-building',
        role: 'project',
        subRoutes: [
          {
            label: 'Brand & URL',
            path: '/project/sub-domain'
          },
          {
            label: 'Project',
            path: '/project/project'
          },
          {
            label: 'Brand & Campaign',
            path: '/project/brand-campaign'
          },
          {
            label: 'Atrributes',
            path: '/project/attributes'
          }
        ]
      },
      {
        label: 'Register',
        path: '/register',
        icon: 'fal fa-inbox',
        role: 'register',
        subRoutes: [
          { label: 'Setting Microsite', path: '/register/setting' },
          // {
          //   label: "Cover page",
          //   path: "/register/registered-cover"
          // },
          {
            label: 'Setting Brand',
            path: '/register/brand-setting'
          },
          {
            label: 'Setting Campaign',
            path: '/register/campaign-setting'
          },
          {
            label: 'Register List',
            path: '/register/registered-project'
          }
          // {
          //   label: "Promotion page",
          //   path: "/register/registered-promotion"
          // }
        ]
      },
      {
        label: 'SEO',
        path: '/seo',
        icon: 'fal fa-globe-asia',
        role: 'seo',
        subRoutes: [
          {
            label: 'Google',
            path: '/seo/google'
          },
          {
            label: 'Facebook',
            path: '/seo/facebook'
          }
        ]
      },
      {
        label: 'Advance Search',
        path: '/advancesearch',
        icon: 'fal fa-search',
        role: 'advance_search',
        subRoutes: [
          {
            label: 'Transport Link',
            path: '/advancesearch/transportlink'
          },
          {
            label: 'Location',
            path: '/advancesearch/location'
          },
          {
            label: 'Price Range',
            path: '/advancesearch/pricerange'
          },
          {
            label: 'Config Color Line',
            path: '/advancesearch/colorline'
          }
        ]
      },
      {
        label: 'News / Articles',
        path: '/newsandarticles',
        icon: 'fal fa-newspaper',
        role: 'news_article',
        subRoutes: [
          {
            label: 'News',
            path: '/newsandarticles/news'
          },
          {
            label: 'Articles',
            path: '/newsandarticles/articles'
          }
        ]
      },
      {
        label: 'Promotion',
        path: '/promotion',
        icon: 'fal fa-newspaper',
        role: 'promotion',
        subRoutes: []
      },
      {
        label: 'Grand Unity Family',
        path: '/gu-family',
        icon: 'fal fa-users',
        role: 'grand_u_fam',
        subRoutes: [
          {
            label: 'Setting Banner',
            path: '/gu-family/banner'
          },
          {
            label: 'Privilege',
            path: '/gu-family/privilege'
          },
          {
            label: 'Upcoming Activity',
            path: '/gu-family/activity'
          },
          {
            label: 'Setup Privilege Category',
            path: '/gu-family/category-privilege'
          }
        ]
      },
      {
        label: 'Join Us',
        path: '/job',
        icon: 'fal fa-users',
        role: 'job',
        subRoutes: []
      },
      {
        label: 'About',
        path: '/about',
        icon: 'fal fa-info-circle',
        role: 'about',
        subRoutes: [
          {
            label: 'Setting Banner',
            path: '/about/banner'
          },
          {
            label: 'Branding',
            path: '/about/branding'
          },
          {
            label: 'Milestone',
            path: '/about/milestone'
          }
        ]
      },
      {
        label: 'Contact',
        path: '/contact',
        icon: 'fal fa-gift',
        role: 'contact',
        subRoutes: [
          {
            label: 'Setting Banner',
            path: '/contact/banner'
          },
          {
            label: 'Set receiver email',
            path: '/contact/receiver'
          },
          {
            label: 'Set Subject & reply email',
            path: '/contact/subject'
          },
          {
            label: 'Message',
            path: '/contact/message'
          },
          {
            label: 'Contact Map',
            path: '/contact/map'
          }
        ]
      },
      {
        label: 'Mobile App',
        path: '/mobile-app',
        icon: 'fal fa-mobile-alt',
        role: 'mobile_app',
        subRoutes: []
      },
      {
        label: 'Calculator',
        path: '/calculator',
        icon: 'fal fa-file-alt',
        role: 'calculator',
        subRoutes: []
      },
      {
        label: 'Foreign Buyer Guide',
        path: '/foreign',
        icon: 'fal fa-address-book',
        role: 'foreign_buyer_guide',
        subRoutes: []
      },
      {
        label: 'Hotdeal',
        path: '/hotdeal',
        icon: 'fal fa-fire',
        role: 'config',
        subRoutes: []
      },
      {
        label: 'Sitemap',
        path: '/sitemap',
        icon: 'fal fa-address-book',
        role: 'sitemap',
        subRoutes: []
      },
      {
        label: 'Terms & Conditions',
        path: '/terms',
        icon: 'fal fa-file-alt',
        role: 'terms_conditions',
        subRoutes: []
      },
      {
        label: 'Privacy',
        path: '/privacy',
        icon: 'fal fa-lock',
        role: 'privacy',
        subRoutes: []
      },
      {
        label: 'File Manager Tools',
        path: '/filemanager',
        icon: 'fal fa-file-alt',
        role: 'file_manager',
        subRoutes: []
      },
      {
        label: 'User & Role Manager',
        path: '/role',
        icon: 'fal fa-users',
        role: 'user_role_manager',
        subRoutes: [
          {
            label: 'User Manager',
            path: '/role/user'
          },
          {
            label: 'Role Manager',
            path: '/role/role'
          }
        ]
      }
    ]
  }),
  methods: {
    view (url) {
      const { innerWidth } = window
      if (innerWidth < 768) {
        this.toggleSideBar()
      }
      this.$router.push(url)
    },
    matchChild (path) {
      return this.$route.path.includes(path) ? 'active' : ''
    },
    matchParent (path) {
      const route_array = this.$route.path.split('/')

      const p = path.replace('/', '')

      if (route_array[1] === p) {
        return 'active'
      }
    },
    getUserList () {
      const userStore = localStorage.getItem('cmsUser')

      if (userStore) {
        this.userInfo = JSON.parse(userStore)
      } else {
        this.$router.push('/login')
      }
    }
  },
  created () {
    this.getUserList()
  }
}
</script>

<style scoped>
ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  #sidebarCollapse span {
    display: none;
  }
}
#sidebar {
  overflow-y: auto;
}
</style>
