import Vue from 'vue'
import Vuesax from 'vuesax'
import BootstrapVue from 'bootstrap-vue'
import ToggleButton from 'vue-js-toggle-button'
import Multiselect from 'vue-multiselect'
import Checkbox from 'vue-material-checkbox'
import PrettyCheck from 'pretty-checkbox-vue/check'
import VueApexCharts from 'vue-apexcharts'
import VueGoogleMaps from 'vue-googlemaps'
import DatePicker from 'vue2-datepicker'
import PrismEditor from 'vue-prism-editor'
import Vuelidate from 'vuelidate'
import Antd from 'ant-design-vue'
import VueAxios from 'vue-axios'
import Axios from 'axios'

import App from './App.vue'
import router from '@/router'
import store from './store'
import './registerServiceWorker'
import 'prismjs'

import 'material-icons/iconfont/material-icons.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-googlemaps/dist/vue-googlemaps.css'
import 'vue-prism-editor/dist/VuePrismEditor.css' // import the styles
import 'prismjs/themes/prism.css'
import 'ant-design-vue/dist/antd.css'

Vue.config.productionTip = false

window.$ = require('jquery')

Vue.use(Vuelidate)
Vue.use(Vuesax)
Vue.use(BootstrapVue)
Vue.use(ToggleButton)
Vue.use(Checkbox)
Vue.use(VueApexCharts)
Vue.use(Antd)
Vue.use(VueAxios, Axios)

Vue.use(VueGoogleMaps, {
  load: {
    // Google API key
    apiKey: process.env.VUE_APP_GOOGLE_MAP,
    // Use new renderer
    useBetaRenderer: false
  }
})

Vue.component('prism-editor', PrismEditor)
Vue.component('p-check', PrettyCheck)
Vue.component('multiselect', Multiselect)
Vue.component('apexchart', VueApexCharts)
Vue.component('date-picker', DatePicker)
Vue.component('multiselect', Multiselect)

new Vue({
  router,
  store,
  render: h => h(App),
  data () {
    return {
      publicPath: '/'
    }
  }
}).$mount('#app')
