import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {
    [ActionFetch.PROJECTS.FLOORS.INDEX]: ({ commit }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(
        `GET /projects/${id}/floorplans`,
        { order_by: 'orderNum' },
        null,
        { apiVersion: '/v2' }
      )
        .then(res => {
          const data = res.data
          if (data.success && data.data) {
            commit(MutationStore.PROJECTS.FLOORS.INDEX, data.data)
          }
          commit(MutationView.SET_LOADING_SCREEN, false)
          return data
        })
        .catch(error => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.PROJECTS.FLOORS.ADD]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(
        API.projects.floors.add,
        { ...params, createdBy: user.id, updatedBy: user.id },
        user.token
      )
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch(error => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.PROJECTS.FLOORS.UPDATE]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(
        API.projects.floors.update(params.id),
        { ...params.floorplanData, createdBy: user.id, updatedBy: user.id },
        user.token
      )
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch(error => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.PROJECTS.FLOORS.DELETE]: ({ commit, rootState }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(
        API.projects.floors.delete(id),
        {},
        rootState.cmsUser.token
      )
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch(error => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },
    [ActionFetch.PROJECTS.FLOORS.SORT]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(
        API.projects.floors.sort,
        { ...params, createdBy: user.id, updatedBy: user.id },
        user.token
      )
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch(error => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    }
  }
}

export default request
