export const HOT_DEAL_ENABLED = 'HOT_DEAL_ENABLED'
export const HOT_DEAL_URL = 'HOT_DEAL_URL'

export default {
  hotdealEnabled: ({ configs }) => {
    const config = configs.find(({ key }) => key === HOT_DEAL_ENABLED)
    return config && config.value === 'true'
  },
  hotdealURL: ({ configs }) => {
    const config = configs.find(({ key }) => key === HOT_DEAL_URL)
    return config ? config.value || '' : ''
  }
}
