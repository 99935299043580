import Vue from 'vue'
import { SET_CAMPAIGNS } from './mutations'

export default {
  async getCampaigns ({ commit }) {
    const { data } = await Vue.axios.get('campaigns', {
      params: {
        pagination: false
      },
      baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
      }
    })

    commit(SET_CAMPAIGNS, data)

    return data
  },
  async updateProjectField (_, { id, field, value }) {
    const { data } = await Vue.axios.patch(`campaigns/${id}`, [
      {
        op: 'replace',
        path: `/${field}`,
        value
      }
    ],
    {
      baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
      }
    })

    return data
  }
}
