export const SET_PROJECTS = 'SET_PROJECTS'
export const SET_PROJECT_MASTERS = 'SET_PROJECT_MASTERS'

export default {
  [SET_PROJECTS] (state, projects) {
    Object.assign(state, { projects })
  },
  [SET_PROJECT_MASTERS] (state, projectMasters) {
    Object.assign(state, { projectMasters })
  }
}
