import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {

    [ActionFetch.PROJECTS.MAIN.INDEX]: ({ commit }, $params = { apiVersion: '/v1' }) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(
        'GET /projects',
        { order_by: 'orderNum', direction: 'asc', limit: 0, cid: $params.cid },
        null,
        { apiVersion: $params.apiVersion }
      )
        .then(res => {
          const data = res.data
          if (data.success && data.data) {
            commit(
              MutationStore.PROJECTS.MAIN.INDEX,
              data.data.map((item) => ({
                ...item,
                isNewProject: item.isNewProject ? 'Y' : 'N',
                isReadyToMoveIn: item.isReadyToMoveIn ? 'Y' : 'N',
                isSoldOut: item.isSoldOut ? 'Y' : 'N',
                isUseTheme: item.isUseTheme ? 'Y' : 'N'
              }))
            )
          }
          commit(MutationView.SET_LOADING_SCREEN, false)
          return data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },

    [ActionFetch.PROJECTS.MAIN.DETAIL]: ({ commit, rootState }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      return AxiosRequest(
        `GET /projects/${id}`,
        {},
        user.token,
        { apiVersion: '/v2' }
      )
        .then(res => {
          const data = res.data
          if (data.success && data.data) {
            Object.assign(data.data, {
              isNewProject: data.data.isNewProject ? 'Y' : 'N',
              isReadyToMoveIn: data.data.isReadyToMoveIn ? 'Y' : 'N',
              isSoldOut: data.data.isSoldOut ? 'Y' : 'N',
              isUseTheme: data.data.isUseTheme ? 'Y' : 'N'
            })

            commit(MutationStore.PROJECTS.MAIN.DETAIL, data.data)
          }
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
        })
    },

    [ActionFetch.PROJECTS.MAIN.ADD]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.main.add, { ...params, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
        })
    },

    [ActionFetch.PROJECTS.MAIN.UPDATE]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.main.update(params.id), { ...params.data, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
        })
    },

    [ActionFetch.PROJECTS.MAIN.DELETE]: ({ commit, rootState }, id) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      return AxiosRequest(API.projects.main.delete(id), {}, rootState.cmsUser.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          const data = res.data
          if (data.success) {
            const projects = rootState.projects.main
            const resp = (projects && projects.filter(each => {
              return each.id !== id
            })) || []
            commit(MutationStore.PROJECTS.MAIN.INDEX, resp)
          }

          return res.data
        })
        .catch((error) => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
        })
    },

    [ActionFetch.PROJECTS.MAIN.SORT]: ({ commit, rootState }, params) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      const user = rootState.cmsUser
      return AxiosRequest(API.projects.main.sort, { ...params, createdBy: user.id, updatedBy: user.id }, user.token)
        .then(res => {
          commit(MutationView.SET_LOADING_SCREEN, false)
          return res.data
        })
        .catch((error) => {
          commit(MutationView.SHOW_MODAL_ALERT, error.message)
          commit(MutationView.SET_LOADING_SCREEN, false)
        })
    },
    [ActionFetch.PROJECTS.MAIN.CLEAR_PROJECT_LIST]: ({ commit }) => {
      commit(MutationStore.PROJECTS.MAIN.CLEAR_PROJECT_LIST)
    }

  }
}

export default request
