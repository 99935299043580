export const SET_MASTERS = 'SET_MASTERS'
export const SET_FORM_INPUT = 'SET_FORM_INPUT'
export const UNSET_FORM_INPUT = 'UNSET_FORM_INPUT'

export default {
  [SET_MASTERS] (state, masters) {
    Object.assign(state, { masters })
  },
  [SET_FORM_INPUT] (state, formInput) {
    Object.assign(state, { formInput })
  },
  [UNSET_FORM_INPUT] (state) {
    Object.assign(state, { formInput: null })
  }
}
