<template>
  <div id="login-page" class="container mt-57 pt-5">
    <div class="card mx-auto border-0">
      <div class="card-body">
        <b-form style="text-align: center" @submit="login">
          <h3 style="text-align: center">Login</h3>
          <br />
          <b-form-group>
            <b-form-input v-model="username" required placeholder="Username" />
          </b-form-group>
          <b-form-group>
            <b-form-input
              type="password"
              v-model="password"
              required
              placeholder="Password"
            />
          </b-form-group>
          <br />
          <b-button type="submit" variant="primary">Login</b-button>
        </b-form>
      </div>
      <div v-show="view.isLoadingScreen" id="loading_screen">
        <img src="@/assets/loading.gif" alt="loading please wait ..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
export default {
  computed: {
    ...mapState(['view'])
  },
  data: () => ({
    username: '',
    password: ''
  }),
  methods: {
    ...mapActions([ActionFetch.AUTHENTICATION]),
    async login (e) {
      try {
        e.preventDefault()
        const { username, password } = this
        this.AUTHENTICATION({ username, password })
      } catch (error) {
        alert(error.message)
      }
    }
  }
}
</script>

<style scoped>
.card {
  width: 300px;
}
</style>

<style>
#login-page {
  min-height: 100vh;
  min-width: 100vw;
  background: rgb(29, 55, 106);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
