import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {

    [ActionFetch.FOREIGNBUYER.DETAIL]: ({ commit }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)

      return AxiosRequest(API.foreignBuyer.getInfo($params.id)).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.FOREIGNBUYER.DETAIL, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    },

    [ActionFetch.FOREIGNBUYER.UPDATE]: ({ commit, rootState }, $params = {}) => {
      commit(MutationView.SET_LOADING_SCREEN, true)
      let user = rootState.cmsUser

      if (!user) {
        user = JSON.parse(localStorage.getItem('cmsUser'))
      }

      $params.updatedBy = user.id

      return AxiosRequest(API.foreignBuyer.update($params.id), $params, user.token).then(($res) => {
        const data = $res.data

        if (data.success && data.data) {
          commit(MutationStore.FOREIGNBUYER.UPDATE, data.data)
        }
        commit(MutationView.SET_LOADING_SCREEN, false)
        return data
      }).catch((error) => {
        commit(MutationView.SHOW_MODAL_ALERT, error.message)
        commit(MutationView.SET_LOADING_SCREEN, false)
      })
    }
  }
}

export default request
