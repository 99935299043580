import { ActionFetch } from '@/store/actionTypes'
import { API } from '@/store/apiPath'
import { AxiosRequest } from '@/store/axiosHelper'
import { MutationStore, MutationView } from '@/store/mutationTypes'

const request = {
  actions: {

    [ActionFetch.EXTERNAL.PROJECT_GULEAD.LIST]: ({ commit }) => {
      if (process.env.VUE_APP_GULEAD_ENABLE === 'false') {
        commit(MutationStore.EXTERNAL.PROJECT_GULEAD.LIST, [
          {
            'ProjectID': '0403',
            'ProjectName': 'พาร์ควิว วิภาวดี 4',
            'ProjectNameEng': 'พาร์ควิว วิภาวดี 4',
            'AbProjectName': '',
            'ProjectType': 'C',
            'CompanyID': 'GUL',
            'BrandID': '00004',
            'BrandName': 'Parkview',
            'TotalUnit': 49,
            'ProjectStatus': '3',
            'ProjectOpen': '30/12/1994',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '30/12/1999',
            'AreaRai': 0,
            'Areangan': 0,
            'AreaSquareWah': 0.00
          },
          {
            'ProjectID': '0501',
            'ProjectName': 'U Sabai',
            'ProjectNameEng': 'U Sabai',
            'AbProjectName': 'USabai',
            'ProjectType': 'C',
            'CompanyID': 'GUL',
            'BrandID': '00000',
            'BrandName': 'U Sabai',
            'TotalUnit': 139,
            'ProjectStatus': '3',
            'ProjectOpen': '30/12/1994',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '30/12/1999',
            'AreaRai': 0,
            'Areangan': 0,
            'AreaSquareWah': 0.00
          },
          {
            'ProjectID': '0601',
            'ProjectName': 'ยู ดีไลท์ @ บางซื่อ สเตชั่น',
            'ProjectNameEng': 'ยู ดีไลท์ @ บางซื่อ สเตชั่น',
            'AbProjectName': 'UDBS',
            'ProjectType': 'C',
            'CompanyID': 'GUL',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 648,
            'ProjectStatus': '3',
            'ProjectOpen': '01/01/1994',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '31/12/1999',
            'AreaRai': 0,
            'Areangan': 0,
            'AreaSquareWah': 0.00
          },
          {
            'ProjectID': '0602',
            'ProjectName': 'ยู ดีไลท์ @ ห้วยขวาง สเตชั่น',
            'ProjectNameEng': 'ยู ดีไลท์ @ ห้วยขวาง สเตชั่น',
            'AbProjectName': 'UDHK',
            'ProjectType': 'C',
            'CompanyID': 'GUL',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 596,
            'ProjectStatus': '3',
            'ProjectOpen': '20/10/2009',
            'ProjectClose': '',
            'ProjectTel': '02-277-9922',
            'ProjectFax': '02-277-9937',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '31/03/2011',
            'AreaRai': 5,
            'Areangan': 1,
            'AreaSquareWah': 48.00
          },
          {
            'ProjectID': '0603',
            'ProjectName': 'ยู ดีไลท์ @ จตุจักร สเตชั่น',
            'ProjectNameEng': 'ยู ดีไลท์ @ จตุจักร สเตชั่น',
            'AbProjectName': 'UDJJ',
            'ProjectType': 'C',
            'CompanyID': 'GUL',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 924,
            'ProjectStatus': '3',
            'ProjectOpen': '01/01/2010',
            'ProjectClose': '',
            'ProjectTel': '02-272-3800',
            'ProjectFax': '02-272-3830',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '15/10/2011',
            'AreaRai': 5,
            'Areangan': 2,
            'AreaSquareWah': 41.00
          },
          {
            'ProjectID': '0604',
            'ProjectName': 'ยู ดีไลท์ @ บางซ่อน สเตชั่น',
            'ProjectNameEng': 'ยู ดีไลท์ @ บางซ่อน สเตชั่น',
            'AbProjectName': 'UDBSN',
            'ProjectType': 'C',
            'CompanyID': 'GUL',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 534,
            'ProjectStatus': '2',
            'ProjectOpen': '16/03/2014',
            'ProjectClose': '',
            'ProjectTel': '02-587-0188',
            'ProjectFax': '02-587-0187',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '01/01/2017',
            'AreaRai': 3,
            'Areangan': 0,
            'AreaSquareWah': 92.00
          },
          {
            'ProjectID': '0701',
            'ProjectName': 'ยู ดีไลท์ @ อ่อนนุช สเตชั่น',
            'ProjectNameEng': 'ยู ดีไลท์ @ อ่อนนุช สเตชั่น',
            'AbProjectName': 'UDON',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 812,
            'ProjectStatus': '3',
            'ProjectOpen': '09/08/2010',
            'ProjectClose': '',
            'ProjectTel': '02-331-9900',
            'ProjectFax': '02-331-9633',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '30/06/2012',
            'AreaRai': 3,
            'Areangan': 3,
            'AreaSquareWah': 55.00
          },
          {
            'ProjectID': '0702',
            'ProjectName': 'ยู ดีไลท์² @ บางซื่อ สเตชั่น',
            'ProjectNameEng': 'ยู ดีไลท์² @ บางซื่อ สเตชั่น',
            'AbProjectName': 'UD2BS',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 645,
            'ProjectStatus': '3',
            'ProjectOpen': '19/03/2011',
            'ProjectClose': '',
            'ProjectTel': '02-556-1551',
            'ProjectFax': '02-556-1554',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '31/01/2013',
            'AreaRai': 3,
            'Areangan': 0,
            'AreaSquareWah': 32.00
          },
          {
            'ProjectID': '0703',
            'ProjectName': 'ยู ดีไลท์ เรสซิเดนซ์ พัฒนาการ-ทองหล่อ',
            'ProjectNameEng': 'ยู ดีไลท์ เรสซิเดนซ์ พัฒนาการ-ทองหล่อ',
            'AbProjectName': 'UDRPK',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 676,
            'ProjectStatus': '3',
            'ProjectOpen': '09/12/2011',
            'ProjectClose': '',
            'ProjectTel': '02-319-3900',
            'ProjectFax': '02-319-3842',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '27/12/2013',
            'AreaRai': 4,
            'Areangan': 1,
            'AreaSquareWah': 81.00
          },
          {
            'ProjectID': '0704',
            'ProjectName': 'ยู ดีไลท์ 3 ประชาชื่น-บางซื่อ',
            'ProjectNameEng': 'ยู ดีไลท์ 3 ประชาชื่น-บางซื่อ',
            'AbProjectName': 'UD3PB',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 782,
            'ProjectStatus': '3',
            'ProjectOpen': '18/11/2011',
            'ProjectClose': '',
            'ProjectTel': '02-556-1551',
            'ProjectFax': '02-556-1554',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '15/08/2013',
            'AreaRai': 5,
            'Areangan': 0,
            'AreaSquareWah': 93.00
          },
          {
            'ProjectID': '0705',
            'ProjectName': 'คอนโด ยู @ หัวหมาก สเตชั่น',
            'ProjectNameEng': 'คอนโด ยู @ หัวหมาก สเตชั่น',
            'AbProjectName': 'CUHM',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00002',
            'BrandName': 'Condo U',
            'TotalUnit': 408,
            'ProjectStatus': '3',
            'ProjectOpen': '27/04/2012',
            'ProjectClose': '',
            'ProjectTel': '02-374-5114',
            'ProjectFax': '02-374-5144',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '15/08/2013',
            'AreaRai': 3,
            'Areangan': 1,
            'AreaSquareWah': 25.00
          },
          {
            'ProjectID': '0706',
            'ProjectName': 'คอนโด ยู รัชโยธิน',
            'ProjectNameEng': 'คอนโด ยู รัชโยธิน',
            'AbProjectName': 'CURT',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00002',
            'BrandName': 'Condo U',
            'TotalUnit': 394,
            'ProjectStatus': '3',
            'ProjectOpen': '16/09/2012',
            'ProjectClose': '',
            'ProjectTel': '02-579-5050',
            'ProjectFax': '02-579-5673',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '25/07/2014',
            'AreaRai': 3,
            'Areangan': 3,
            'AreaSquareWah': 44.00
          },
          {
            'ProjectID': '0708',
            'ProjectName': 'ยู ดีไลท์ รัตนาธิเบศร์',
            'ProjectNameEng': 'ยู ดีไลท์ รัตนาธิเบศร์',
            'AbProjectName': 'UDRB',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 989,
            'ProjectStatus': '2',
            'ProjectOpen': '08/12/2012',
            'ProjectClose': '',
            'ProjectTel': '02-580-0808',
            'ProjectFax': '02-580-0755',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '15/12/2014',
            'AreaRai': 5,
            'Areangan': 1,
            'AreaSquareWah': 10.00
          },
          {
            'ProjectID': '0709',
            'ProjectName': 'ยู ดีไลท์ @ หัวหมาก สเตชั่น',
            'ProjectNameEng': 'ยู ดีไลท์ @ หัวหมาก สเตชั่น',
            'AbProjectName': 'UDHM',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 867,
            'ProjectStatus': '3',
            'ProjectOpen': '01/02/2013',
            'ProjectClose': '',
            'ProjectTel': '02-116-7576',
            'ProjectFax': '-',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '31/03/2015',
            'AreaRai': 6,
            'Areangan': 0,
            'AreaSquareWah': 5.00
          },
          {
            'ProjectID': '0711',
            'ProjectName': 'คอนโด ยู วิภา-ลาดพร้าว',
            'ProjectNameEng': 'คอนโด ยู วิภา-ลาดพร้าว',
            'AbProjectName': 'CUVL',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00002',
            'BrandName': 'Condo U',
            'TotalUnit': 364,
            'ProjectStatus': '3',
            'ProjectOpen': '01/08/2013',
            'ProjectClose': '',
            'ProjectTel': '02-276-5051',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '31/10/2014',
            'AreaRai': 3,
            'Areangan': 0,
            'AreaSquareWah': 3.00
          },
          {
            'ProjectID': '0712',
            'ProjectName': 'ยู ดีไลท์ เรสซิเดนซ์ ริเวอร์ฟร้อนท์ พระราม 3',
            'ProjectNameEng': 'ยู ดีไลท์ เรสซิเดนซ์ ริเวอร์ฟร้อนท์ พระราม 3',
            'AbProjectName': 'UDRR',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00003',
            'BrandName': 'Residence',
            'TotalUnit': 1088,
            'ProjectStatus': '2',
            'ProjectOpen': '25/01/2014',
            'ProjectClose': '',
            'ProjectTel': '02-048-6096',
            'ProjectFax': '02-048-6098',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '15/08/2016',
            'AreaRai': 6,
            'Areangan': 2,
            'AreaSquareWah': 34.00
          },
          {
            'ProjectID': '0713',
            'ProjectName': 'คอนโด ยู แคมปัส รังสิต-เมืองเอก',
            'ProjectNameEng': 'คอนโด ยู แคมปัส รังสิต-เมืองเอก',
            'AbProjectName': 'UCRM',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00002',
            'BrandName': 'Condo U',
            'TotalUnit': 448,
            'ProjectStatus': '3',
            'ProjectOpen': '14/11/2013',
            'ProjectClose': '',
            'ProjectTel': '02-536-4591',
            'ProjectFax': '02-536-4598',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '30/06/2015',
            'AreaRai': 3,
            'Areangan': 2,
            'AreaSquareWah': 10.00
          },
          {
            'ProjectID': '0714',
            'ProjectName': 'ยู ดีไลท์ @ ตลาดพลู สเตชั่น',
            'ProjectNameEng': 'ยู ดีไลท์ @ ตลาดพลู สเตชั่น',
            'AbProjectName': 'UDTP',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 976,
            'ProjectStatus': '2',
            'ProjectOpen': '16/03/2014',
            'ProjectClose': '',
            'ProjectTel': '02-477-1868',
            'ProjectFax': '02-477-1878',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '31/03/2016',
            'AreaRai': 5,
            'Areangan': 3,
            'AreaSquareWah': 72.80
          },
          {
            'ProjectID': '0715',
            'ProjectName': 'ยู ดีไลท์ รัชวิภา ',
            'ProjectNameEng': 'ยู ดีไลท์ รัชวิภา ',
            'AbProjectName': 'UDRV',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00001',
            'BrandName': 'U Delight',
            'TotalUnit': 881,
            'ProjectStatus': '2',
            'ProjectOpen': '25/07/2015',
            'ProjectClose': '',
            'ProjectTel': '02-561-3643',
            'ProjectFax': '02-561-0364',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '30/03/2017',
            'AreaRai': 6,
            'Areangan': 0,
            'AreaSquareWah': 69.00
          },
          {
            'ProjectID': '0717',
            'ProjectName': 'คอนโด ยู เกษตร-นวมินทร์',
            'ProjectNameEng': 'Condo U Kaset-Nawamin',
            'AbProjectName': 'CUKN',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00002',
            'BrandName': 'Condo U',
            'TotalUnit': 445,
            'ProjectStatus': '2',
            'ProjectOpen': '04/02/2017',
            'ProjectClose': '15/12/2017',
            'ProjectTel': '025221040',
            'ProjectFax': '022533263',
            'ProjectEmail': '',
            'ProjectWebsite': 'www.grandunity.co.th',
            'BuildCompleteDate': '01/11/2017',
            'AreaRai': 4,
            'Areangan': 3,
            'AreaSquareWah': 11.00
          },
          {
            'ProjectID': '0718',
            'ProjectName': 'เซียล่า ศรีปทุม',
            'ProjectNameEng': 'CIELA Sripatum',
            'AbProjectName': 'CLSP',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00005',
            'BrandName': 'CIELA',
            'TotalUnit': 903,
            'ProjectStatus': '2',
            'ProjectOpen': '10/03/2018',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '31/08/2019',
            'AreaRai': 6,
            'Areangan': 1,
            'AreaSquareWah': 17.00
          },
          {
            'ProjectID': '0719',
            'ProjectName': 'เดอ ลาพีส จรัญ 81',
            'ProjectNameEng': 'De LAPIS Charan 81',
            'AbProjectName': 'DLCR',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00006',
            'BrandName': 'De LAPIS',
            'TotalUnit': 637,
            'ProjectStatus': '2',
            'ProjectOpen': '19/05/2018',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '31/05/2019',
            'AreaRai': 3,
            'Areangan': 1,
            'AreaSquareWah': 3.70
          },
          {
            'ProjectID': '0720',
            'ProjectName': 'เดนิม จตุจักร',
            'ProjectNameEng': 'DENIM Jatujak',
            'AbProjectName': 'DNJJ',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00007',
            'BrandName': 'DENIM',
            'TotalUnit': 1817,
            'ProjectStatus': '0',
            'ProjectOpen': '25/09/2019',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '',
            'AreaRai': 9,
            'Areangan': 0,
            'AreaSquareWah': 1.60
          },
          {
            'ProjectID': '0721',
            'ProjectName': 'แมสซารีน รัชโยธิน',
            'ProjectNameEng': 'MAZARINE Ratchayothin',
            'AbProjectName': 'MZRT',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00008',
            'BrandName': 'MAZARINE',
            'TotalUnit': 476,
            'ProjectStatus': '0',
            'ProjectOpen': '06/10/2018',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '30/06/2020',
            'AreaRai': 3,
            'Areangan': 3,
            'AreaSquareWah': 46.60
          },
          {
            'ProjectID': '0722',
            'ProjectName': 'อนิล สาทร 12',
            'ProjectNameEng': 'ANIL SATHORN 12',
            'AbProjectName': 'ANST',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00010',
            'BrandName': 'ANIL',
            'TotalUnit': 222,
            'ProjectStatus': '0',
            'ProjectOpen': '23/05/2019',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '15/02/2022',
            'AreaRai': 1,
            'Areangan': 2,
            'AreaSquareWah': 41.30
          },
          {
            'ProjectID': '0723',
            'ProjectName': 'คาร่า อารีย์-พระราม 6',
            'ProjectNameEng': 'Kara Ari-Rama6',
            'AbProjectName': 'KRR6',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00009',
            'BrandName': 'KARA',
            'TotalUnit': 28,
            'ProjectStatus': '2',
            'ProjectOpen': '29/03/2019',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '21/06/2019',
            'AreaRai': 0,
            'Areangan': 1,
            'AreaSquareWah': 79.10
          },
          {
            'ProjectID': '0724',
            'ProjectName': 'เซียล่า จรัญฯ 13 สเตชั่น',
            'ProjectNameEng': 'Ciela Charan 13 Station',
            'AbProjectName': 'CLCR',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00005',
            'BrandName': 'CIELA',
            'TotalUnit': 360,
            'ProjectStatus': '0',
            'ProjectOpen': '08/06/2019',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '15/12/2020',
            'AreaRai': 1,
            'Areangan': 3,
            'AreaSquareWah': 93.60
          },
          {
            'ProjectID': '0725',
            'ProjectName': 'เดอะ ไพรเวท เรสซิเด้นซ์ ราชดำริ',
            'ProjectNameEng': 'The Private Residence Rajdamri',
            'AbProjectName': 'TPRR',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00003',
            'BrandName': 'Residence',
            'TotalUnit': 29,
            'ProjectStatus': '2',
            'ProjectOpen': '01/09/2018',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '25/09/2010',
            'AreaRai': 1,
            'Areangan': 0,
            'AreaSquareWah': 4.00
          },
          {
            'ProjectID': '0726',
            'ProjectName': 'เซียล่า เจริญนคร ',
            'ProjectNameEng': 'CIELA Charoen Nakhon',
            'AbProjectName': 'CLCN',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00005',
            'BrandName': 'CIELA',
            'TotalUnit': 105,
            'ProjectStatus': '5',
            'ProjectOpen': '01/06/2019',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '',
            'AreaRai': 1,
            'Areangan': 0,
            'AreaSquareWah': 15.40
          },
          {
            'ProjectID': '0727',
            'ProjectName': 'ไคลน์ ทองหล่อ',
            'ProjectNameEng': 'KLEIN THONG LO',
            'AbProjectName': 'KLTL',
            'ProjectType': 'C',
            'CompanyID': 'GU',
            'BrandID': '00011',
            'BrandName': 'KLEIN',
            'TotalUnit': 0,
            'ProjectStatus': '5',
            'ProjectOpen': '26/06/2019',
            'ProjectClose': '',
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '',
            'AreaRai': 3,
            'Areangan': 1,
            'AreaSquareWah': 63.70
          },
          {
            'ProjectID': '0728',
            'ProjectName': 'บลู สุขุมวิท 89',
            'ProjectNameEN': 'BLUE Sukhumvit 89',
            'ProjectType': 'C',
            'SubBUID': null,
            'SubBUName': null,
            'SubBUShortName': null,
            'BrandName': 'BLUE',
            'BrandID': '00012',
            'AbProjectName': 'BSK89',
            'CompanyID': 'GU',
            'ProjectStatus': '5',
            'ProjectOpen': '15/01/2020',
            'ProjectClose': null,
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': null,
            'AreaRai': '0',
            'Areangan': '0',
            'AreaSquareWah': '0.00',
            'TotalUnit': 0,
            'Counter': [],
            'Address': []
          },
          {
            'ProjectID': '0729',
            'ProjectName': 'บลู สุขุมวิท 105',
            'ProjectNameEN': 'BLUE Sukhumvit 105',
            'ProjectType': 'C',
            'SubBUID': null,
            'SubBUName': null,
            'SubBUShortName': null,
            'BrandName': 'BLUE',
            'BrandID': '00012',
            'AbProjectName': 'BSK105',
            'CompanyID': 'GU',
            'ProjectStatus': '5',
            'ProjectOpen': '15/01/2020',
            'ProjectClose': null,
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': null,
            'AreaRai': '3',
            'Areangan': '1',
            'AreaSquareWah': '18.00',
            'TotalUnit': 387,
            'Counter': [],
            'Address': [
              {
                'AddressNo': '',
                'Soi': 'ลาซาล 57',
                'Moo': '',
                'Road': 'สุขุมวิท105',
                'SubDistrict': 'บางนา',
                'District': 'บางนา',
                'Province': 'กรุงเทพมหานคร',
                'PostCode': '10260'
              }
            ]
          },
          {
            'ProjectID': '0730',
            'ProjectName': 'บลู พหลโยธิน 35',
            'ProjectNameEN': 'BLUE Phahon Yothin 35',
            'ProjectType': 'C',
            'SubBUID': null,
            'SubBUName': null,
            'SubBUShortName': null,
            'BrandName': 'BLUE',
            'BrandID': '00012',
            'AbProjectName': 'BPH35',
            'CompanyID': 'GU',
            'ProjectStatus': '5',
            'ProjectOpen': '15/01/2020',
            'ProjectClose': null,
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': null,
            'AreaRai': '2',
            'Areangan': '1',
            'AreaSquareWah': '97.00',
            'TotalUnit': 322,
            'Counter': [],
            'Address': [
              {
                'AddressNo': '',
                'Soi': 'วิภาวดีรังสิต 38',
                'Moo': '',
                'Road': '',
                'SubDistrict': 'ลาดยาว',
                'District': 'จตุจักร',
                'Province': 'กรุงเทพมหานคร',
                'PostCode': '10900'
              }
            ]
          },
          {
            'ProjectID': 'HO',
            'ProjectName': 'Head Office---',
            'ProjectNameEN': null,
            'ProjectType': 'C',
            'SubBUID': null,
            'SubBUName': null,
            'SubBUShortName': null,
            'BrandName': 'U Sabai',
            'BrandID': '00000',
            'AbProjectName': 'HO',
            'CompanyID': 'GUL',
            'ProjectStatus': '2',
            'ProjectOpen': '30/12/1994',
            'ProjectClose': null,
            'ProjectTel': '',
            'ProjectFax': '',
            'ProjectEmail': '',
            'ProjectWebsite': '',
            'BuildCompleteDate': '30/12/1999',
            'AreaRai': '0',
            'Areangan': '0',
            'AreaSquareWah': '0.00',
            'TotalUnit': 0,
            'Counter': [
              {
                'CounterName': 'HO-001',
                'Description': 'Head Office'
              },
              {
                'CounterName': 'HO-Recurring',
                'Description': 'HO-Recurring'
              },
              {
                'CounterName': 'HO-DirectDebit',
                'Description': 'HO-DirectDebit'
              },
              {
                'CounterName': 'HO-BookingOnline',
                'Description': 'HO-BookingOnline'
              },
              {
                'CounterName': 'HO-0002',
                'Description': ''
              }
            ],
            'Address': []
          }
        ])
      } else {
        commit(MutationView.SET_LOADING_SCREEN, true)

        const params = {
          'api-user': process.env.VUE_APP_GULEAD_USERNAME,
          'api-password': process.env.VUE_APP_GULEAD_PASSWORD,
          'api-token': process.env.VUE_APP_GULEAD_TOKEN,
          'rem-host': process.env.VUE_APP_GULEAD_REMHOST,
          'api-url': process.env.VUE_APP_GULEAD_APIURL
        }
        const options = {
          endpoint: process.env.VUE_APP_GULEAD_ENDPOINT,
          apiVersion: ''
        }

        return AxiosRequest(API.external.projectGuLead.list, params, null, options)
          .then(res => {
            const data = res.data
            if (data.status === 1) {
              commit(MutationStore.EXTERNAL.PROJECT_GULEAD.LIST, data.data)
            }
            commit(MutationView.SET_LOADING_SCREEN, false)
            return data
          })
          .catch((error) => {
            commit(MutationView.SHOW_MODAL_ALERT, error.message)
            commit(MutationView.SET_LOADING_SCREEN, false)
          })
      }
    }

  }
}

export default request
